import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useServices } from "../../context/services";
import queryString from 'query-string';

import './Forms.scss';

import { useTranslation } from 'react-i18next';

import ErrorPopup from './ErrorPopup';

function Login(props) {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const { services } = useServices();
  const [userNameError, setUserNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [genericError, setError] = useState("");

  const query = queryString.parse(props.location.search);
  
  const { t } = useTranslation();

  function validate() {
    const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValidEmail = pattern.test(userName);
    setUserNameError(isValidEmail ? "" : t('error_msg.email', 'Email inválido!'));

    const isValidPassword = password.length >= 8 ? true : false;
    setPasswordError(isValidPassword ? null : t('error_msg.password', 'Password inválida!'));

    return isValidEmail && isValidPassword;
  }

  function postLogin() {
    if (!validate()) return;

    services.authApi.login(userName, password)
      .then(result => {
        if (result) {
          //setAuthTokens(result.data.authenticateUserWithPassword.item);
          setLoggedIn(true);
        } else {
          //console.log(result.errors);
          setError(t('error_msg.incorrect_login', 'O email ou password estão incorrectos.'));
          setIsError(true);
        }
      }).catch(e => {
        setError(t('error_msg.incorrect_login', 'O email ou password estão incorrectos.'));
        setIsError(true);
      });
  }

  function closePopup(e) {
    e.preventDefault();
    setError("");
  }

  if (isLoggedIn) {
    return <Redirect to={query.redirect ? query.redirect : "/admin"} />;
  }

  return (
    <div className="menu">
      <div className="app forms">
      <header className="admin-header">
        <figure className="logo">
         <img className="admin-logo" src={t('common.logo_url', '/images/logo.svg')} alt={t('common.site_name', 'Menu Autêntico')} />
        </figure>
      </header>
      <div className="content">
        <div className="form">
          <div className="m-bottom-6 txt-primary">
              <h2>{t('admin_login.title', 'Login')}</h2>
          </div>
          <form id="" >
            <input
              type="email"
              id="userName"
              value={userName}
              placeholder={t('placeholder.email', 'E-mail')}
              onFocus={(e) => { setUserNameError(""); }}
              onChange={(e) => setUserName(e.target.value)}
            />
            {userNameError ? <div className="error">{userNameError}</div> : null}
            <input
              type="password"
              id="password"
              value={password}
              placeholder={t('placeholder.password', 'Password')}
              onFocus={(e) => { setPasswordError(""); }}
              onChange={(e) => setPassword(e.target.value)}
              className="m-top-4"
            />
            {passwordError ? <div className="error">{passwordError}</div> : null}
            {genericError ? <div className="error">{genericError}</div> : null}
          </form>
          <div className="m-top-8 txt-center">
            <a href="/forgot-password" className="link-primary">
              <span>{t('admin_login.txt_forgot', 'Esqueceu-se da password?')}</span>
            </a>
          </div>
          <div className="m-top-8">
            <a onClick={postLogin} className="btn-primary">
              {t('btn.login', 'Login')}
            </a>
          </div>
        </div>
        <div className="m-top-8 txt-primary txt-center">
          <h5>{t('admin_login.txt_signup', 'Ainda não tem conta? Registe-se.')}</h5>
        </div>
        <div className="m-top-8 m-bottom-10">
          <a href="/signup" className="btn-secundary">
              {t('btn.signup', 'Registar')}
          </a>
        </div>
      </div>
      <ErrorPopup message={genericError} onClose={closePopup} />
    </div>
    </div>
  );
}

export default Login;