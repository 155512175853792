export default function makeMenusApi({
	query,
	mutation,
}) {
	const menuFragment = `{
            id,
            name,
            qrcode,
            url,
            restaurant,
            bgTab, 
            bgColor,
            bgImage,
            coverTab, 
            coverColor,
            coverImage,
            btnColor,
            darkMode,
            product,
            user { 
                id,
                country {
                    currency
                    lang_pt
                }
            },
            views,
            visible,
            published,
            show_shortcut,
            show_english,
            shortcut {
                id,
                name {
                    id,
                    pt,
                    en
                },
                icon {
                    id,
                    gold {
                        publicUrl
                    }
                }
            }
            lists {
                id,
                order,
                default,
                name {
                    id,
                    pt,
                    en
                },
                icon {
                    id,
                    file {
                        publicUrl
                    }
                },
                subtab,
                hide,
                parent{
                    id
                }
            }
            order {
                id
            },
            logo
        }
    `;
    const reportFragment = `{ 
        id,
        name,
        user { 
            name, 
            code,
            nif,
            country {
                name
            }
        }, 
        url,
        views,
        qrcode,
        restaurant,
        order { id }, 
        published, 
        show_english, 
        show_shortcut,
        createdAt 
    }`;

	return {
		add: menu => mutation(`
            mutation {
                createMenu(data: {
                    show_shortcut: ${menu.show_shortcut},
                    show_english: ${menu.show_english},
                    name: "${menu.name}",
                    restaurant: "${menu.restaurant}",
                    user: {
                        connect: {
                            id: "${menu.user}"
                        }
                    }
                }) { id }
            }
        `),
        delete: menuId => mutation(`
            mutation {
                deleteMenu(id:"${menuId}") { id }
            }
        `),
        update: menu => mutation(`
            mutation {
                updateMenu(id: "${menu.id}", data: {
                    show_shortcut: ${menu.show_shortcut},
                    show_english: ${menu.show_english},
                    name: "${menu.name}",
                    visible: ${menu.visible},
                    restaurant: "${menu.restaurant}",
                    logo: "${menu.logo}",
                }) { id }
            }
        `),
        updateStyle: menu => mutation(`
            mutation {
                updateMenu(id: "${menu.id}", data: {
                    bgTab: "${menu.bgTab}",
                    bgColor: "${menu.bgColor}",
                    bgImage: "${menu.bgImage}",
                    coverTab: "${menu.coverTab}",
                    coverColor: "${menu.coverColor}",
                    coverImage: "${menu.coverImage}",
                    btnColor: "${menu.btnColor}",
                    darkMode: ${menu.darkMode},
                    product: "${menu.product}",
                }) { id }
            }
        `),
		getAll: (page = 0, pageSize = 10) => query(`
			query {
				allMenus ${menuFragment}
			}
        `),
        getReport: () => query(`
			query {
				allMenus ${reportFragment}
			}
		`),
		getById: id => query(`
			query {
				Menu(where: { id: "${id}" }) ${menuFragment}
			}
        `),
        getAllByUserId: userId => query(`
			query {
				allMenus(where: { user: { id: "${userId}" } }) ${menuFragment}
			}
        `),
        publishMenuById: id => mutation(`
            mutation {
                updateMenu(id: "${id}", data: {
                    published: true,
                    visible: true
                }) { id }
            }
        `),
        updateViews: (id, views) => mutation(`
            mutation {
                updateMenu(id: "${id}", data: {
                    views: ${views}
                }) { id }
            }
        `)
	};
}