import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "./DeleteContent.scss";
import { ServicesContext } from "../../../context/services";

class ResetThemeContent extends Component {

    constructor(props) {
        super(props);

        this.id = props.data.id;
        this.settings = props.data.settings;

        this.onConfirm = this.onConfirm.bind(this);
        this.onComplete = props.onComplete;
    }

    onConfirm() { 
        const { services } = this.context;
        const { t } = this.props;
        const darkMode = this.settings.darkMode === t('admin_edit_theme.txt_dark', 'Escuro');
        const { bgTab, bgColor, bgImage, coverTab, coverColor, coverImage, btnColor, id, product } = this.settings;
        
        services.menusApi.updateStyle({
            bgTab,
            bgColor,
            bgImage,
            coverTab,
            coverColor,
            coverImage,
            btnColor,
            id,
            darkMode,
            product
        }).then(({ updateMenu }) => {
            if (updateMenu && updateMenu.id) {
                this.onComplete();
            } else console.log("Error updating menu");
        }).catch(error => console.log(error));
        
    } 

    render() {
        const { t } = this.props;
        return (
            <div className="delete">
                <h2>{t('common.txt_question_reset_theme', 'Tem a certeza que pretende repor a configuração original?')}</h2>
                <div className="options">
                    <button className="option" onClick={this.onConfirm}>{t('common.txt_yes', 'Sim')}</button>
                    <button className="option" onClick={this.onComplete}>{t('common.txt_no', 'Não')}</button>
                </div>
            </div>
        )
    }
}

ResetThemeContent.contextType = ServicesContext;

export default withTranslation()(ResetThemeContent);