import React, { Component } from 'react';

import { ServicesContext } from '../../../context/services';
import { withTranslation } from 'react-i18next';
import RadioGroup from '../partials/RadioGroup';
import "./AddItemContent.scss";

class EditItemContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            area: 'edit',
            loading: false,
            uploading: false,
            id: props.data.id,
            show_english: props.data.show_english,
            description: { pt: '', en: '' },
            name: { pt: '', en: '' },
            language: process.env.REACT_APP_VERSION !== 'sb:user:en' ? 'pt' : 'en',
            items: [],
            price: '',
            image: '',
            errors: { name: { pt: '', en: '' }, description: { pt: '', en: '' }, price: '' },
            parent: props.data.parent,
        };

        this.onComplete = props.onComplete;
        this.addItem = this.addItem.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitItems = this.handleSubmitItems.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
    }

    componentDidMount() {
        const id = this.state.id;
        const { services } = this.context;
        const { t } = this.props;

        services.itemsApi.getById(id)
            .then(({ Item }) => {
                const name = Item.name;
                const description = Item.description;
                const price = Item.price;
                const image = Item.image;
                const visible = Item.visible || Item.visible === null ? t('common.txt_yes', 'Sim') : t('common.txt_no', 'Não');
                this.setState({ name, description, price, image, visible });
                if(this.props.data.show_sub_items) this.handleItems();
            });
    }

    validate() {
        let errors = {};
        
        if(this.state.items.length > 0 && !this.state.price) this.state.price = 0;

        let { name, description, price, show_english } = this.state;
        const { t } = this.props;

        errors.name = {} 
        
        let isValidNamePT = true;
        if(process.env.REACT_APP_VERSION != 'sb:user:en'){
            isValidNamePT = name.pt.length == 0 ? null : true;
            errors.name.pt = (isValidNamePT ? null : "Nome do produto inválido!");
        }

        // errors.description = {}
        // const isValidDescriptionPT = description.pt.length == 0 ? null : true;
        // errors.description.pt = (isValidDescriptionPT ? null : "Descrição do produto inválida!");

        let isValidNameEN = true;
        // let isValidDescriptonEN = true;
        
        if (show_english) {
            isValidNameEN = name.en.length === 0 ? null : true;
            errors.name.en = (isValidNameEN ? null : "Name of the product is invalid!");

            // isValidDescriptonEN = description.en.length === 0 ? null : true;
            // errors.description.en = (isValidDescriptonEN ? null : "Description of the product is invalid!");
        }
        
        errors.price = "";
        const isValidPrice = price.length == 0 ? null : true;
        errors.price = (isValidPrice ? null :  t('error_msg.value', "Valor inválido!"));

        this.setState({ errors });

        return isValidNamePT && isValidNameEN && isValidPrice;
    }

    handleSubmit = (event) => {
        event.preventDefault();
        
        if (!this.validate()) return;

        this.setState({ loading: true });

        const { services } = this.context;

        services.labelsApi.update(this.state.name)
            .then(({ updateLabel }) => {
                if (updateLabel && updateLabel.id) {
                    this.updateDescription(services);
                } else console.log("Error updating Label");
            }).catch(error => console.log(error));
    }

    updateDescription = (services) => {
        services.descriptionApi.update(this.state.description)
            .then(({ updateDescription }) => {
                if (updateDescription && updateDescription.id) {
                    this.updatePrice(services);
                } else console.log("Error updating Description");
            }).catch(error => console.log(error));
    }

    updatePrice = (services) => {
        const data = `{ price: ${this.state.price}, image: "${this.state.image}" }`;

        services.itemsApi.update(this.state.id, data)
            .then(({ updateItem }) => {
                if (updateItem && updateItem.id) {
                    this.onComplete();
                } else console.log("Error updating the Item");
            }).catch(error => console.log(error));
    }

    handleChange = (event) => {
        let { name, value } = event.target;
        if (name == 'price' && value) {
            value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        }
        this.setState({ [name]: value });
    }

    handleLanguage = (e) => {
        const { name, value } = e.target;
        const { language } = this.state;

        const obj = this.state[name];
        obj[language] = value.replace('"', '');

        this.setState({ [name]: obj });
    }

    handleLanguageChange = (data) => {
        this.setState({ language: data.selected });
    }

    onFileChanged = (event) => {
        let state = this.state;

        const view = this;
        const { files } = event.target;
        const file = files[0];
        const url = process.env.REACT_APP_WEBSITE_NAME + '/gateway/amazon?file-name=' + file.name + '&file-type=' + file.type;

        this.setState({ uploading: true });

        fetch(url, {
            method: 'GET'
        }).then(function (response) {
            return response.json();
        }).then(function (result) {
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', result.signedRequest, true);

            file.signedRequest = result.signedRequest;

            var formData = new FormData();
            formData.append("file", file);

            xhr.onload = function () {
                if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                    view.setState({ uploading: false, image: result.url });
                }
            };

            xhr.send(file);
        });
    }

    removeImage = (event) => {
        event.preventDefault();
        this.setState({ image: "" });
    }

    getIndex = (items, id) => {
        let i = 0;
        for (i; i < items.length; i++) {
            if (items[i].id === id) return i;
        }
    }

    handleItems = (event) => {
        const id = this.state.id;
        const { services } = this.context;

        this.setState({ loading: true });

        services.subItemsApi.getByItem(id)
            .then(({ allSubItems }) => {
                this.setState({ items: allSubItems, area: 'items', loading: false });
            });
    }

    addItem = (event) => {
        event.preventDefault();

        const id = this.state.id;
        const { services } = this.context;
        const { items } = this.state;

        this.setState({ loading: true });

        const data = `{ name: { create: { pt: "", en: "" }  }, price: 0, item: { connect: { id: "${id}" } } }`;

        services.subItemsApi.add(data)
            .then(({ createSubItem }) => {
                if (createSubItem) items.push(createSubItem);
                this.setState({ items, loading: false });
            });
    }

    deleteItem = (event) => {
        event.preventDefault();

        const { items } = this.state;
        const { services } = this.context;

        const { currentTarget } = event;
        const id = currentTarget.dataset.id;

        const index = this.getIndex(items, id);

        this.setState({ loading: true });

        services.subItemsApi.delete(id)
            .then(({ deleteSubItem }) => {
                if (deleteSubItem) items.splice(index, 1);
                this.setState({ items, loading: false });
            });
    }

    handleItemChange = (e) => {
        const { parentNode } = e.target;
        let { name, value } = e.target;
        const { items, language } = this.state;
        
        if (name == 'price' && value) {
            value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        }

        const i = this.getIndex(items, parentNode.id);

        switch (name) {
            case 'name':
                items[i].name[language] = value;
                break;
            case 'price':
                items[i].price = value;
                break;
        }

        this.setState({ items: items });
    }

    handleSubmitItems = (event) => {
        event.preventDefault();
        console.log("MISSING VALIDATIONS");

        const { items } = this.state;
        const { services } = this.context;

        let data = '', prices = '';

        for (let i = 0; i < items.length; i++) {
            if (data.length) data += ',';
            data += `{ id: "${items[i].name.id}", data: { pt: "${items[i].name.pt}", en: "${items[i].name.en}" } }`;

            if (prices.length) prices += ',';
            prices += `{ id: "${items[i].id}", data: { price: ${items[i].price} } }`;
        }

        services.labelsApi.updateMany(data)
            .then(({ updateLabels }) => {
                if (updateLabels) {
                    this.updatePrices(services, prices);
                }
            });
    }

    updatePrices = (services, prices) => {
        services.subItemsApi.updateMany(prices)
            .then(({ updateSubItems }) => {
                if (updateSubItems) {
                    this.setState({ area: 'edit' });
                }
            });
    }

    onDeleteMainItem = (event) =>{
        event.preventDefault();
        this.onComplete({popup:'delete', id:this.state.id});
    }

    changeTab = (event) => {
        event.preventDefault();
        this.onComplete({popup:'change_tab', id:this.state.id, tab: this.state.parent});
    }

    handleToggle = (event) => {
        this.setState({
            [event.id]: event.selected
        }, () => {
            if(event.id === "visible") this.updateVisible();
        });
    }

    updateVisible = () => {
        const { t } = this.props;
        const { services } = this.context;
        const data = `{ visible: ${this.state.visible === t('common.txt_yes', 'Sim')} }`;

        services.itemsApi.update(this.state.id, data)
            .then(({ updateItem }) => {
                if (updateItem && updateItem.id) {
                    //this.onComplete();
                } else console.log("Error updating the Item");
            }).catch(error => console.log(error));
    }

    renderEdit() {
        const { show_english, language, loading, uploading, image, items, parent } = this.state;
        const { t } = this.props;

        return (
            <div className={loading ? "add-item loading" : "add-item"}>
                <h2>{t('admin_edit_product.title', 'Editar produto')}</h2>
                <form autoComplete="off">
                    <div className="fields">
                        <input type="text" placeholder={t('placeholder.name', 'Nome')} name="name" onChange={this.handleLanguage} value={this.state.name[language]} />
                        {this.state.errors.name.pt && language === "pt" ? <div className="error">{this.state.errors.name.pt}</div> : null}
                        {this.state.errors.name.en && language === "en" ? <div className="error">{this.state.errors.name.en}</div> : null}
                        <textarea type="text" placeholder={t('placeholder.description', 'Descrição')} name="description" onChange={this.handleLanguage} value={this.state.description[language]} />
                    </div>
                    <div className="row m-top-4">
                        <input type="text" placeholder="0.00" name="price" onChange={this.handleChange} value={this.state.price} />
                        {show_english ? <RadioGroup id="language" name="   language" options={["pt", "en"]} selected={this.state.language} onChange={this.handleLanguageChange}/> : null}
                    </div>
                    <div className="row">
                        <div>{this.state.errors.price ? <div className="error">{this.state.errors.price}</div> : null}</div>
                        {this.state.errors.name.pt && this.state.language == "en" ? <div className="error">Produto em português em falta</div> : null}
                        {this.state.errors.name.en && this.state.language == "pt"? <div className="error">Produto em inglês em falta</div> : null}
                    </div>

                    <label htmlFor="logo" className={uploading ? "button button-upload-image upload m-top-4" : "button button-upload-image m-top-4"}>
                        <span>{image ? t('btn.image', 'imagem.jpg') : t('btn.upload_product_img', 'Carregar imagem do produto') }</span>
                        <span>{image ? t('btn.upload_replace', 'clique para substituir') : t('common.txt_optional', '(opcional)') }</span>
                        {image ? <a href="#" className="remove" onClick={this.removeImage}><span className="icon-trash"></span></a> : null}
                    </label>
                    <input type="file" id="logo" onChange={this.onFileChanged} accept="image/x-png,image/gif,image/jpeg" />
                </form>

                <div className="m-top-4">
                    <a href="#" className="variant-button" onClick={this.handleItems}>
                        <span>{t('btn.edit_variant', 'Editar variantes de produto')}</span>
                        <span className="icon-edit-bold"></span>
                    </a>
                </div>
                
                <div className="m-top-4">
                    <a href="#" className="variant-button" onClick={this.changeTab}>
                        <span>{parent && parent.ItemList.subtab === false ? t('btn.change_sub_tab', "Alterar sub-separador") : t('btn.change_tab', "Alterar separador")}</span>
                        <span className="icon-edit-bold"></span>
                    </a>
                </div>
                <div className="m-top-4">
                    <a href="#" className="delete-button" onClick={this.onDeleteMainItem}>
                        <span>{t('btn.delete_product', 'Apagar produto')}</span>
                        <span className="icon-trash"></span>
                    </a>
                </div>
                <div className="row m-top-4">
                    <div className="label">
                        <h2>{t('placeholder.public_product', 'Produto visível')}</h2>
                    </div>
                    <RadioGroup id="visible" name="number" options={[t('common.txt_yes', 'Sim'), t('common.txt_no', 'Não')]} selected={this.state.visible} onChange={this.handleToggle}/>
                </div>
                <div className="m-top-4">
                    <a href="#" onClick={this.handleSubmit} className="btn-primary">
                        {t('btn.save', 'Guardar')}
                    </a>
                </div>
            </div>
        )
    }
 
    renderItems() {
        const { show_english, loading, items, language } = this.state;
        const { t } = this.props;

        const onDelete = (e) => this.deleteItem(e);
        const onChange = (e) => this.handleItemChange(e);

        return (
            <div className={loading ? "add-variant loading" : "add-variant"}>
                <h2>{process.env.REACT_APP_VERSION != 'sb:user:en' ? t('admin_edit_product.txt_variant_of', 'Variantes de ') + this.state.name.pt : t('admin_edit_product.txt_variant_of', 'Variantes de ') +this.state.name.en}</h2>
                <form autoComplete="off">
                    {
                        items.length ?
                            items.map(function (item) {
                                return (
                                    <div className="variant-item" key={item.id}>
                                        <div className="row m-top-4" id={item.id}>
                                            <input type="text" placeholder={t('placeholder.name', "Nome")} name="name" value={item.name[language]} onChange={onChange} />
                                            <input type="text" placeholder="0.00" name="price" maxLength="8" value={item.price} onChange={onChange} />
                                            <div className="del-variant"><div href="#" data-id={item.id} onClick={onDelete}><span className="icon-trash"></span></div></div>
                                        </div>
                                    </div>
                                )
                            }) : null
                    }
                    {
                        items.length < 5 ?
                            <div className="m-top-8">
                                <a href="#" className="variant-button" onClick={this.addItem}>
                                    <span>{t('btn.add_variant', "Adicionar variante de produto")}</span>
                                    <span className="icon-add"></span>
                                </a>
                            </div> : null
                    }
                    {
                        items.length ?
                            <div className="row m-top-4">
                                {show_english && process.env.REACT_APP_VERSION != 'sb:user:en' ? <RadioGroup id="language" name="language" options={["pt", "en"]} selected={this.state.language} onChange={this.handleLanguageChange}/> : null}
                            </div> : null
                    }
                    <div className="m-top-4">
                        <a href="#" onClick={this.handleSubmitItems} className="btn-primary">
                            {items.length ? t('btn.save', "Guardar") : t('btn.back', "Voltar")}
                        </a>
                    </div>
                </form>
            </div>
        )
    }

    render() {
        const { area } = this.state;

        switch (area) {
            case 'edit': return this.renderEdit(); break;
            case 'items': return this.renderItems(); break;
            default: return null;
        }
    }
}

EditItemContent.contextType = ServicesContext;

export default withTranslation()(EditItemContent);