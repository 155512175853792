import axios from 'axios';

import initApollo, { makeQuery, makeMutation } from '../lib/apollo';

import makeMenusApi from './menus';
import makeAuthApi from './auth';
import makeUsersApi from './users';
import makeItemListApi from './itemlist';
import makeIconsApi from './icons';
import makeItemsApi from './items';
import makeSubItemsApi from './subitems';
import makeMaterialsApi from './materials';
import makeDescriptionApi from './descriptions';
import makeOrdersApi from './orders';
import makeLabelsApi from './labels';
import makeCountriesApi from './countries';
import makeProductsApi from './products';

const apolloClient = initApollo();
const query = makeQuery(apolloClient);
const mutation = makeMutation(apolloClient);
const request = (url, options) => axios
	.request(Object.assign(options || {}, { url: process.env.REACT_APP_WEBSITE_NAME + '/gateway/' + url }))
	.then(response => (response && response.data) || response);

const menusApi = makeMenusApi({ query, mutation });
const authApi = makeAuthApi({ query, mutation, request });
const usersApi = makeUsersApi({ query, mutation });
const itemListApi = makeItemListApi({ query, mutation });
const iconsApi = makeIconsApi({ query, mutation });
const itemsApi = makeItemsApi({ query, mutation });
const labelsApi = makeLabelsApi({ query, mutation });
const descriptionApi = makeDescriptionApi({ query, mutation });
const materialsApi = makeMaterialsApi({ query, mutation });
const ordersApi = makeOrdersApi({ query, mutation });
const subItemsApi = makeSubItemsApi({ query, mutation });
const countriesApi = makeCountriesApi({ query, mutation });
const productsApi = makeProductsApi({ query, mutation });

const services = {
    menusApi,
    authApi,
    usersApi,
    itemListApi,
    labelsApi,
    iconsApi,
    itemsApi,
    subItemsApi,
    descriptionApi,
    materialsApi,
    countriesApi,
    ordersApi,
    productsApi
};

export default services;

export {
    menusApi,
    authApi,
    usersApi,
    itemListApi,
    subItemsApi,
    iconsApi,
    itemsApi,
    materialsApi,
    countriesApi,
    ordersApi,
};