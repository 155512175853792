export default function makeIconsApi({
	query,
	mutation,
}) {
	const iconFragment = `{
        id,
        name,
        file {
            publicUrl
        }
    }`;

	return {
		getAll: id => query(`
			query {
				allIcons ${iconFragment}
			}
        `),
	};
}