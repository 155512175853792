import React, { Component } from 'react'
//import v8n from "v8n";
import "./AddMenuContent.scss";
import { ServicesContext } from '../../../context/services';
import { withTranslation  } from 'react-i18next';

import RadioGroup from '../partials/RadioGroup';

class AddMenuContent extends Component {

    constructor(props) {
        super(props);
        const { t } = this.props;

        this.state = {
            name: "",
            restaurant: "",
            public: false,
            english: t('common.txt_no', 'Não'),
            shortcut: t('common.txt_yes', 'Sim'),
            user: props.data.user,
            loading: false,
            errors: {}
        }

        this.onComplete = props.onComplete;
        this.handleToggle = this.handleToggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    validate() {
        let errors = {};
        let { name, restaurant } = this.state;
        const { t } = this.props;
        
        const isValidName = name.length == 0 ? null : true;
        errors.name = (isValidName ? null : t('error_msg.menu_name', "O nome do menu não é válido!") );

        const isValidRestaurant = restaurant.length == 0 ? null : true;
        errors.restaurant = (isValidRestaurant ? null :  t('error_msg.restaurant_name', "O nome do restaurante não é válido!"));

        this.setState({ errors });

        return isValidName && isValidRestaurant;
    }

    handleFocus(event) {
        const { name } = event.target;
        const { errors } = this.state;
        errors[name] = "";
        this.setState({ errors });
    }

    handleChange = (event) => {
        const { name, value } = event.target
        this.setState({ [name]: value.replace('"', '') });
    }

    handleToggle = (event) => {
        this.setState({ [event.id]: event.selected });
    }

    handleSubmit = (event) => {
        event.preventDefault();

        if (!this.validate())
            return;

        const { t } = this.props;
        this.setState({ loading: true });

        const { services } = this.context;
        const show_english = this.state.english === t('common.txt_yes', 'Sim');
        const show_shortcut = this.state.shortcut === t('common.txt_yes', 'Sim');
        const { name, restaurant, user } = this.state;

        services.menusApi.add({
            name,
            restaurant,
            user,
            show_english,
            show_shortcut,
        })
            .then(({ createMenu }) => {
                if (createMenu && createMenu.id) {
                    console.log('onComplete');
                    this.onComplete();
                } else console.log("Error adding the menu");
            }).catch(error => console.log(error));
    }

    render() {
        const { errors, loading } = this.state;
        const { t, data } = this.props;

        return (
            <div className={loading ? "edit-menu loading" : "edit-menu"}>
                <h2>{t('admin_add_menu.title', 'Criar menu')}</h2>
                <form autoComplete="off">
                    <input
                        type="text"
                        placeholder={t('placeholder.menu_name', "Nome do menu")}
                        name="name"
                        value={this.state.name}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                    />
                    {errors.name ? <div className="error">{errors.name}</div> : null}
                    <input
                        type="text"
                        placeholder={t('placeholder.restaurant_name', "Nome do estabelecimento")}
                        name="restaurant"
                        value={this.state.restaurant}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        className="m-top-4"
                    />
                    {errors.restaurant ? <div className="error">{errors.restaurant}</div> : null}
                </form>
                <div className="row m-top-4 drinks">
                    <div className="label">
                        {process.env.REACT_APP_VERSION === 'sb:user:en' ?
                            <h2>{t('placeholder.portuguese_version', 'Versão em Português')}</h2>
                            :
                            <h2>{t('placeholder.english_version', 'Versão em Inglês')}</h2>
                        }
                    </div>
                    <RadioGroup id="english" name="number" options={[t('common.txt_yes', 'Sim'), t('common.txt_no', 'Não')]} selected={this.state.english} onChange={this.handleToggle} />
                </div>
                <div className="row m-top-4 drinks">
                    <div className="label">
                        <h2>{t('placeholder.drinks_shortcut', 'Atalho para bebidas')}</h2>
                    </div>
                    <RadioGroup id="shortcut" name="number" options={[t('common.txt_yes', 'Sim'), t('common.txt_no', 'Não')]} selected={this.state.shortcut} onChange={this.handleToggle} />
                </div>
                <div className="m-top-8">
                    <a href="#" onClick={this.handleSubmit} className="btn-primary">
                        {t('btn.save', 'Guardar')}
                    </a>
                </div>
            </div >
        )
    }
}

AddMenuContent.contextType = ServicesContext;

export default withTranslation()(AddMenuContent);