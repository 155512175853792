import React, { Component } from "react";
// import Carousel from 'react-elastic-carousel';
import "./AdminMenu.scss";
import { ServicesContext } from "../../context/services";
import { withTranslation } from 'react-i18next';

import Styles from "../utils/styles";
import Popup from "./popups/Popup";
import Tabs from "./partials/Tabs";

class AdminSubTab extends Component {
  constructor(props) {
    super(props);

    this.id = this.props.match.params.id;

    this.state = {
      popup: null,
      data: null
    };

    this.confirmDelete = this.confirmDelete.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.deleteTab = this.deleteTab.bind(this);
    this.editTab = this.editTab.bind(this);
    this.addTab = this.addTab.bind(this);
    this.sortTabs = this.sortTabs.bind(this);
  }

  componentDidMount() {
    const id = this.id;
    const { services } = this.context;

    services.itemListApi.getById(id)
      .then(({ ItemList }) => this.setState({ data: { ItemList } }));
  }

  addTab(e) {
    e.preventDefault();
    const show_english = this.state.data && this.state.data.ItemList.menu.show_english;
    const menu = this.state.data && this.state.data.ItemList.menu;
    this.setState({ popup: { name: "add_tab", show_english, parent: this.id, menu: menu } });
  }

  editTab(e) {
    e.preventDefault();
    const { currentTarget } = e;
    const show_english = this.state.data && this.state.data.ItemList.menu.show_english;
    this.setState({
      popup: {
        name: "edit_tab",
        id: currentTarget.dataset.id,
        show_english,
        onDelete: this.confirmDelete,
      },
    });
  }

  sortTabs(list) {
    this.setState({ popup: { name: "sort_tabs", list: list, onSave: this.closePopup } });
  }

  closePopup(e) {
    if (e) e.preventDefault();
    this.setState({ popup: null, data: null });
    this.componentDidMount();
  }

  confirmDelete(id) {
    this.setState({
      popup: { name: "delete", id: id, onDelete: this.deleteTab },
    });
  }

  deleteTab(id) {
    const { services } = this.context;

    services.itemListApi
      .delete(id)
      .then(({ deleteItemList }) => {
        if (deleteItemList && deleteItemList.id) {
          this.closePopup();
        } else console.log("Error deleting ItemList");
      })
      .catch((error) => console.log(error));
  }


  render() {
    const { data, popup } = this.state;
    const { t } = this.props;

    if (data && data.ItemList) {
      const style = Styles.checkStyle(data.ItemList.menu);
      
      return (
        <div className="menu" style={ style.background.backgroundColor || style.background.backgroundImage ? {backgroundColor:'#eee'} : null }>
          <div className="app opened" style={style.background}>
            <header className="detail-header">
                <a className="back" href={"/admin/menu/" + data.ItemList.menu.id}>
                  <span className="icon-arrow-back" style={style ? style.customText : {}}></span>
                </a>
            </header>
            <div className="list">
              <div className="title sub" style={style ? style.buttons.background : {}}>
                <img className="sep-icon" src={data.ItemList.icon.file.publicUrl} style={{filter: style && style.isDarkMode ? 'invert(0.7)' : 'invert(0)' }}/>
                <h2 style={style.text}>{process.env.REACT_APP_VERSION === 'sb:user:en' ? data.ItemList.name.en : data.ItemList.name.pt}</h2>
              </div>
            </div>
            <div className="m-top-12">
              <Tabs list={this.id} nocategory={data.ItemList.items.length > 0 ? true : false } style={style} addTab={this.addTab} editTab={this.editTab} sortTabs={this.sortTabs} label={ data.ItemList.name.pt } subtab={true}></Tabs>
            </div>
            <div className="m-bottom-10"></div>

            <Popup data={popup} onClose={this.closePopup} style={style}/>
          </div>
        </div>
      );
    } else {
      return (
        <div className="loader">
          <img src="/images/loader.gif" alt="" />
        </div>
      );
    }
  }
}

AdminSubTab.contextType = ServicesContext;

export default withTranslation()(AdminSubTab);
