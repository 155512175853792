import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "./EditThemeContent.scss";
import { ServicesContext } from '../../../context/services';
import { CirclePicker, CompactPicker, TwitterPicker } from 'react-color';
import RadioGroup from '../partials/RadioGroup';

class EditThemeContent extends Component {

    constructor(props) {
        super(props);
        const { t } = this.props;

        this.state = {
            id: props.data.menu.id,
            bgTab: props.data.menu.bgTab || "color",
            bgImage: props.data.menu.bgImage || "",
            bgColor: props.data.menu.bgColor || "#ab2429",
            coverTab: props.data.menu.coverTab || "color",
            coverImage: props.data.menu.coverImage || "",
            coverColor: props.data.menu.coverColor || "",
            btnColor: props.data.menu.btnColor || "#caa36c",
            uploading: false,
            darkMode: props.data.menu.darkMode ? t('admin_edit_theme.txt_dark', 'Escuro') : t('admin_edit_theme.txt_light', 'Claro'),
            product: props.data.menu.product || "super_bock",
        };
        this.onComplete = props.onComplete;
        this.onReset = props.data.onReset;

        this.removeImage = this.removeImage.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onFileChanged = this.onFileChanged.bind(this);
        this.handleReset = this.handleReset.bind(this);
        //this.onResetTheme = this.onResetTheme.bind(this);

        this.colorOptions = ['#FFB692', '#FFE09E', '#7FDBB6', '#97E0A6', '#ADE0FF', '#ACB8C3', '#EF7A97', '#FBC2CC', '#CC8DFA'];
    }

    /*TABS*/
    onBgTab = (value) => {
        this.setState({
            bgTab: value,
        });
    }

    onCoverTab = (value) => {
        this.setState({
            coverTab: value,
        });
    }

    /* BACKGROUND */
    onColorBackgroundPicked = (color, event) => {
        this.setState({ bgColor: color.hex });
    }

    onImageBackgroundSelected = (value) => {
        this.setState({
            bgImage: value,
        });
    }

    /* COVER */
    onColorCoverPicked = (color, event) => {
        this.setState({ coverColor: color.hex });
    }

    onImageCoverSelected = (value) => {
        this.setState({
            coverImage: value,
        });
    }

    onFileChanged = (event) => {
        let state = this.state;

        const view = this;
        const { files } = event.target;
        const file = files[0];
        const url = process.env.REACT_APP_WEBSITE_NAME + '/gateway/amazon?file-name=' + file.name + '&file-type=' + file.type;

        this.setState({ uploading: true });

        fetch(url, {
            method: 'GET'
        }).then(function (response) {
            return response.json();
        }).then(function (result) {
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', result.signedRequest, true);

            file.signedRequest = result.signedRequest;

            var formData = new FormData();
            formData.append("file", file);

            xhr.onload = function () {
                if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                    view.setState({ uploading: false, coverImage: result.url });
                }
            };

            xhr.send(file);
        });
    }

    removeImage = (event) => {
        event.preventDefault();
        this.setState({ coverImage: "" });
    }

    /* BUTTONS */
    onColorButtonPicked = (color, event) => {
        this.setState({ btnColor: color.hex });
    }

    /* SHADOWS */
    handleToggle = (event) => {
        this.setState({ [event.id]: event.selected });
    }

    
    onProductSelected = (value) => {
        this.setState({
            product: value,
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.submitChanges();
    }

    handleReset = (event) => {
        event.preventDefault();
        const { t } = this.props;

        this.setState({
            bgTab: 'color',
            bgImage: null,
            bgColor: "#ab2429",
            coverTab: 'image',
            coverImage: '/images/bg-menu.jpg',
            coverColor: null,
            btnColor: "#caa36c",
            darkMode: t('admin_edit_theme.txt_light', 'Claro'),
            product: 'super_bock'
        }, this.onResetTheme);

    }

    onResetTheme = () => {
        this.onReset(this.state);
    }

    submitChanges = () => {
        const { t } = this.props;
        
        const { services } = this.context;
        const darkMode = this.state.darkMode === t('admin_edit_theme.txt_dark', 'Escuro');
        const { bgTab, bgColor, bgImage, coverTab, coverColor, coverImage, btnColor, id, product } = this.state;

        services.menusApi.updateStyle({
            bgTab,
            bgColor,
            bgImage,
            coverTab,
            coverColor,
            coverImage,
            btnColor,
            id,
            darkMode,
            product
        }).then(({ updateMenu }) => {
            if (updateMenu && updateMenu.id) {
                this.onComplete();
            } else console.log("Error updating menu");
        }).catch(error => console.log(error));
    }

    render() {
        const { bgTab, bgColor, bgImage, coverTab, coverImage, uploading, btnColor, darkMode, product } = this.state;
        const { t } = this.props;
        return (
            <div className="edit-theme">
                <h2>{t('admin_edit_theme.title', 'Editar tema')}</h2>
                <div className="m-top-8">
                    <h3>{t('admin_edit_theme.txt_cover', 'Cover')}</h3>
                    <div className="tab-buttons m-top-4">
                        <div className={coverTab === "color" ? "tab-button active" : "tab-button"} onClick={() => { this.onCoverTab("color") }}>{t('btn.color', 'Cor')}</div>
                        <div className={coverTab === "texture" ? "tab-button active" : "tab-button"} onClick={() => { this.onCoverTab("texture") }}>{t('btn.textures', 'Textura')}</div>
                    </div>
                    <div className="tab-buttons">
                        <div className={coverTab === "image" ? "tab-button active" : "tab-button"} onClick={() => { this.onCoverTab("image") }}>{t('btn.images', 'Imagem')}</div>
                        <div className={coverTab === "none" ? "tab-button active" : "tab-button"} onClick={() => { this.onCoverTab("none") }}>{t('btn.none', 'Nenhum')}</div>
                    </div>
                    <div className="m-top-4">
                        {coverTab === "color" ? <div><TwitterPicker color={coverImage} onChangeComplete={this.onColorCoverPicked} colors={this.colorOptions} width="100%" /></div> : null}
                        {coverTab === "texture" ? <div>
                            <div className="row">
                                <div className={coverImage === "texture1" ? "item-texture active" : "item-texture"} onClick={() => { this.onImageCoverSelected("texture1") }}><img src="/images/theme/textures/thumb1.jpg" /></div>
                                <div className={coverImage === "texture2" ? "item-texture active" : "item-texture"} onClick={() => { this.onImageCoverSelected("texture2") }}><img src="/images/theme/textures/thumb2.jpg" /></div>
                                <div className={coverImage === "texture3" ? "item-texture active" : "item-texture"} onClick={() => { this.onImageCoverSelected("texture3") }}><img src="/images/theme/textures/thumb3.jpg" /></div>

                            </div>
                            <div className="row">
                                <div className={coverImage === "texture4" ? "item-texture active" : "item-texture"} onClick={() => { this.onImageCoverSelected("texture4") }}><img src="/images/theme/textures/thumb4.jpg" /></div>
                                <div className={coverImage === "texture5" ? "item-texture active" : "item-texture"} onClick={() => { this.onImageCoverSelected("texture5") }}><img src="/images/theme/textures/thumb5.jpg" /></div>
                                <div className={coverImage === "texture6" ? "item-texture active" : "item-texture"} onClick={() => { this.onImageCoverSelected("texture6") }}><img src="/images/theme/textures/thumb6.jpg" /></div>
                            </div>
                        </div> : null}
                        {this.state.coverTab === "image" ? <div>
                            <label htmlFor="logo" className={uploading ? "button button-upload-image upload m-top-4" : "button button-upload-image m-top-4"}>
                                <span>{coverImage.indexOf("amazon") > 1 ? "image.png" : t('btn.upload_img', 'Carregar imagem')}</span>
                                {coverImage.indexOf("amazon") > 1 ? <span>{t('btn.upload_replace', 'clique para substituir')}</span> : <div></div>}
                                {coverImage.indexOf("amazon") > 1 ? <a href="#" className="remove" onClick={this.removeImage}><img src="/images/admin/icons/delete.png" /></a> : null}
                            </label>
                            <input type="file" id="logo" onChange={this.onFileChanged} accept="image/x-png,image/gif,image/jpeg" />
                        </div> : null}
                    </div>
                </div>
                <div className="m-top-8"> 
                    <h3>{t('admin_edit_theme.txt_product', 'Produto')}</h3>
                    <div className="tab-buttons m-top-4">
                        <div className={product === "super_bock" ? "tab-button sm active" : "tab-button sm"} onClick={() => { this.onProductSelected("super_bock") }}><img src="/images/admin/theme/super_bock.png"/></div>
                        <div className={product === "carlsberg" ? "tab-button sm active" : "tab-button sm"} onClick={() => { this.onProductSelected("carlsberg") }}><img src="/images/admin/theme/carlsberg.png"/></div>
                    </div>
                    <div className="tab-buttons">
                        <div className={product === "super_bock_stout" ? "tab-button sm active" : "tab-button sm"} onClick={() => { this.onProductSelected("super_bock_stout") }}><img src="/images/admin/theme/super_bock_stout.png"/></div>
                        <div className={product === "somersby" ? "tab-button sm active" : "tab-button sm"} onClick={() => { this.onProductSelected("somersby") }}><img src="/images/admin/theme/somersby.png"/></div>
                    </div>
                    <div className="tab-buttons">
                        <div className={product === "1927" ? "tab-button sm active" : "tab-button sm"} onClick={() => { this.onProductSelected("1927") }}><img src="/images/admin/theme/1927.png"/></div>
                    </div>
                </div>
                <div className="m-top-8">
                    <h3>{t('admin_edit_theme.txt_background', 'Background')}</h3>
                    <div className="tab-buttons m-top-4">
                        <div className={bgTab === "color" ? "tab-button active" : "tab-button"} onClick={() => { this.onBgTab("color") }}>{t('btn.color', 'Color')}</div>
                        <div className={bgTab === "texture" ? "tab-button active" : "tab-button"} onClick={() => { this.onBgTab("texture") }}>{t('btn.textures', 'Textures')}</div>
                    </div>
                    <div className="m-top-4">
                        {bgTab === "color" ?
                            <div><TwitterPicker color={bgColor} onChangeComplete={this.onColorBackgroundPicked} colors={this.colorOptions} width="100%" /></div> :
                            <div>
                                <div className="row">
                                    <div className={bgImage === "texture1" ? "item-texture active" : "item-texture"} onClick={() => { this.onImageBackgroundSelected("texture1") }}><img src="/images/theme/textures/thumb1.jpg" /></div>
                                    <div className={bgImage === "texture2" ? "item-texture active" : "item-texture"} onClick={() => { this.onImageBackgroundSelected("texture2") }}><img src="/images/theme/textures/thumb2.jpg"/></div>
                                    <div className={bgImage === "texture3" ? "item-texture active" : "item-texture"} onClick={() => { this.onImageBackgroundSelected("texture3") }}><img src="/images/theme/textures/thumb3.jpg" /></div>

                                </div>
                                <div className="row">
                                    <div className={bgImage === "texture4" ? "item-texture active" : "item-texture"} onClick={() => { this.onImageBackgroundSelected("texture4") }}><img src="/images/theme/textures/thumb4.jpg" /></div>
                                    <div className={bgImage === "texture5" ? "item-texture active" : "item-texture"} onClick={() => { this.onImageBackgroundSelected("texture5") }}><img src="/images/theme/textures/thumb5.jpg" /></div>
                                    <div className={bgImage === "texture6" ? "item-texture active" : "item-texture"} onClick={() => { this.onImageBackgroundSelected("texture6") }}><img src="/images/theme/textures/thumb6.jpg" /></div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="m-top-8">
                    <h3>{t('admin_edit_theme.txt_buttons', 'Botões')}</h3>
                    <div className="m-top-4"><TwitterPicker color={btnColor} onChangeComplete={this.onColorButtonPicked} colors={this.colorOptions} width="100%" /></div>
                </div>
                
                <div className="row m-top-8">
                    <div className="label"><h3>{t('admin_edit_theme.txt_dark_mode', 'Tema escuro')}</h3></div>
                    <RadioGroup id="darkMode" name="darkMode" options={[t('admin_edit_theme.txt_light', 'Claro'), t('admin_edit_theme.txt_dark', 'Escuro')]} selected={darkMode} onChange={this.handleToggle} />
                </div>
                <div className="m-top-8">
                    <a onClick={this.handleSubmit} className="btn-primary">
                        {t('btn.save', 'Guardar')}
                    </a>
                </div>
                <div className="m-top-4">
                    <a onClick={this.handleReset} className="btn-tertiary">
                        {t('btn.reset_custom_theme', 'Repor configuração original')}
                    </a>
                </div>
            </div>
        )
    }
}

EditThemeContent.contextType = ServicesContext;

export default withTranslation()(EditThemeContent);