import React, { Component } from 'react';
import { ServicesContext } from '../../../context/services';
import { withTranslation } from 'react-i18next';

import Carousel from 'react-elastic-carousel';

const ITEMS_PER_PAGE = 20;

class Tabs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            loading: true,
            menu: props.menu,
            list: props.list,
            nocategory: props.nocategory,
            label: props.label,
            style: props.style,
            isSubTab: props.subtab,
        };

        this.addTab = props.addTab;
        this.editTab = props.editTab;
        this.sortTabs = props.sortTabs;

        this.onSortTabs = this.onSortTabs.bind(this);
    }

    sortByOrder(items) {
        items.sort(function (a, b) {
            if(a.order === null){
                let max = items.length;
                if(a.order > items.length || b.order > items.length) max = (a.order < b.order ? b.order : a.order) + 1;
                a.order = max;
            } 
            return a.order - b.order;
        });
        return items;
    }

    componentDidMount() {
        const { menu, list, items } = this.state;
        const { services } = this.context;

        this.setState({ loading: true });

        if(!this.state.list){
            services.itemListApi.getByMenu(menu)
                .then(({ allItemLists }) => {
                    const items = this.sortByOrder(allItemLists);
                    this.setState({ loading: false, data: { allItemLists: items } });
                })
                .catch(err => {
                    this.setState({ loading: false });
                });
        }else{
            services.itemListApi.getSubTabs(list)
                .then(({ allItemLists }) => {
                    const items = this.sortByOrder(allItemLists);
                    this.setState({ loading: false, data: { allItemLists: items } });
                })
                .catch(err => {
                    this.setState({ loading: false });
                });
        }
    }

    chunkArray(myArray, chunk_size) {
        var results = [],
            tmpArray = myArray.concat();

        while (tmpArray.length) {
            results.push(tmpArray.splice(0, chunk_size));
        }
        return results;
    }

    onSortTabs(e) {
        e.preventDefault();
        this.sortTabs(this.state.data.allItemLists);
    }

    showSortButton(label, style) {
        if (this.state.data.allItemLists.length > 1) {
            return (
                <div className="footer-buttons m-top-4">
                    <div onClick={this.onSortTabs} className="btn-secundary" style={style.buttons.border}>
                        <span>{label}</span>
                    </div>
                </div>
            )
        }
    }

    checkDefault(tab, style) {
        if (!tab.default) {
            return (
                <a
                    data-id={tab.id}
                    href="#"
                    className="edit"
                    onClick={this.editTab}
                >
                <span className="icon-edit" style={style}></span>
                </a>
            );
        }
    }

    checkItems(){
        const { list, style, nocategory, label} = this.state;
        const { t } = this.props;
        
        if(nocategory) {
            return(
                <li key={list.id}>
                    <a className="item" href={"/admin/detail/" + list} style={style.buttons.background}>
                        <div className="button">
                            {process.env.REACT_APP_VERSION != 'sb:user:en' ? <span style={style.text}>{ label ? label : 'Sem categoria' }</span> : <span  style={style.text}> { label ? label : 'No category' }</span> }
                        </div>
                    </a>
                </li>
            )
        }
    }

    render() {
        const { data, style, loading, isSubTab } = this.state;
        const { t } = this.props;

        if (!loading && data && data.allItemLists) {
            const pages = this.chunkArray(data.allItemLists, ITEMS_PER_PAGE);
            if (pages.length === 0) return (
                <div className="tabs">
                    <ul key="0" className="icons">
                        <li>
                            <a href="#" className="item new" onClick={this.addTab} style={{backgroundColor: style.isDarkMode ? "#5C5C5C" : "#fff" }}>
                                <div className="button">
                                    <figure className="cross" style={style.buttons.border}></figure>
                                    <span style={style.buttons.color}>{isSubTab ? t('btn.add_sub_tab', 'Adicionar Sub Separador') : t('btn.add_tab', 'Adicionar Separador')}</span>
                                </div>
                            </a>
                        </li>
                        {this.checkItems()}
                    </ul>
                </div>
            )
            const pagesHtml = pages.map((page, index) => {
                return (
                    <ul key={index} className={data.allItemLists.length < 8 ? 'icons' : 'icons col-3'}>
                        <li>
                            <a href="#" className="item new" onClick={this.addTab} style={{backgroundColor: style.isDarkMode ? "#5C5C5C" : "#fff" }}>
                                <div className="button">
                                    <figure className="cross" style={style.buttons.border}></figure>
                                    <span style={style.buttons.color}>{isSubTab ? t('btn.add_sub_tab', 'Adicionar Sub Separador') : t('btn.add_tab', 'Adicionar Separador')}</span>
                                </div>
                            </a>
                        </li>
                        {this.checkItems()}
                        {page.map(list => {
                            return (
                                <li key={list.id} style={{opacity: list.hide ? '0.75' : '1'}}>
                                    <a className="item" href={list.subtab ? "/admin/tab/" + list.id : "/admin/detail/" + list.id} style={style.buttons.background}>
                                        <div className="button">
                                            <img
                                                className="icon"
                                                src={list.icon.file.publicUrl}
                                                alt=""
                                                style={{filter: style.isDarkMode ? 'invert(0.7)' : 'invert(0)' }}
                                            />
                                            {process.env.REACT_APP_VERSION != 'sb:user:en' ? <span style={style.text}>{list.name ? list.name.pt : ""}</span> : <span  style={style.text}>{list.name ? list.name.en : ""}</span> }
                                        </div>
                                    </a>
                                    {this.checkDefault(list, style.text)}
                                </li>
                            )
                        })}
                    </ul>
                )
            });

            return (
                <div className="tabs">
                    <Carousel ref={ref => (this.carousel = ref)} showArrows={false} pagination={pages.length > 1 ? true : false} enableSwipe={true} enableMouseSwipe={true}>{pagesHtml}</Carousel>
                    {this.showSortButton(isSubTab ? t('btn.sort_sub_tabs', 'Ordenar sub separadores') : t('btn.sort_tabs', 'Ordenar separadores'), style)}
                </div>
            );
        }
        else {
            return (
                <div className="tabs">
                    <div className="loader">
                        <img src="/images/loader.gif" alt="" />
                    </div>
                </div>
            );
        }
    }
}

Tabs.contextType = ServicesContext;

export default withTranslation()(Tabs);