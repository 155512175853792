import React, { Component } from 'react';
import { ServicesContext } from '../../../context/services';
import { withTranslation } from 'react-i18next';
import "./PublishMenuContent.scss";
import SpecialStepper from "../partials/SpecialStepper";

class PublishMenuContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            step: 0,
            name: '',
            city: '',
            postal: '',
            address: '',
            contact: '',
            data: null,
            materials: {},
            loading: true,
            id: props.data.id,
            userId: props.data.userId,
            errors: {}
        };

        this.onComplete = props.onComplete;
        this.onPublished = props.data.onPublished;
        this.checkStep = this.checkStep.bind(this);
        this.checkOrder = this.checkOrder.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleSteps = this.handleSteps.bind(this);
        this.getMaterials = this.getMaterials.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePublish = this.handlePublish.bind(this);
    }

    componentDidMount() {
        const { services } = this.context;

        const userId = this.state.userId;

        services.ordersApi.getAllByUserId(userId)
            .then(({ allOrders }) => {
                if (!allOrders.length && process.env.REACT_APP_VERSION != "sb:user:en") {
                    this.getMaterials();
                } else {
                    this.handlePublish();
                }
            });
    }

    getMaterials = () => {
        const { services } = this.context;

        services.materialsApi.getAll()
            .then(({ allMaterials }) => {
                let materials = {};
                const items = allMaterials;
                for (var i = 0; i < items.length; i++) {
                    materials[items[i].id] = {
                        group: items[i].group,
                        stock: items[i].stock,
                        image: items[i].image,
                        name: items[i].name,
                        max: items[i].max,
                        active: true,
                        value: 0
                    }
                }
                this.setState({ materials, data: { allMaterials }, loading: false });
            });
    }

    handlePublish = (event) => {
        if(event) event.preventDefault();

        const { services } = this.context;

        this.setState({ loading: true });

        services.menusApi.publishMenuById(this.state.id)
            .then(({ updateMenu }) => {
                if (updateMenu && updateMenu.id) {
                    this.onPublished();
                } else console.log("Error updating menu");
            }).catch(error => console.log(error));
    }

    handleNext = (event) => {
        event.preventDefault();
        this.setState({ step: 1 });
    }

    validate() {
        let errors = {};
        let {  name, address, city, postal, contact } = this.state;

        const isValidName = name.length < 3 ? null : true;
        errors.name = (isValidName ? null : "Nome do responsável inválido!");

        const isValidAddress = address.length < 5 ? null : true;
        errors.address = (isValidAddress ? null : "Morada inválida!");

        const isValidPostal = postal.length < 5 ? null : true;
        errors.postal = (isValidPostal ? null : "Código postal inválido (XXXX-XXX)!");

        const isValidLocal = city.length < 3 ? null : true;
        errors.city = (isValidLocal ? null : "Localidade inválida!");

        const isValidContact = contact.length < 9 ? null : true;
        errors.contact = (isValidContact ? null : "Contacto inválido!");

        this.setState({ errors });

        return isValidName && isValidAddress && isValidPostal && isValidLocal && isValidContact;
    }

    handleSubmit = (event) => {
        event.preventDefault();
        console.log("VERIFY VALIDATIONS");
        
        if (!this.validate()) return;

        this.setState({ loading: true });

        const { materials } = this.state;

        let products = '', i = 1, value;
        for (var key in materials) {
            value = materials[key].value;

            if (value) {
                if (products) products += ', ';
                products += `product_${i}: { connect: { id: "${key}" } }, quantity_${i}: ${value}`; i++;
            }
        }

        const menu = `menu: { connect: { id: "${this.state.id}" } }`;
        const data = `{ name: "${this.state.name}", address: "${this.state.address}", city: "${this.state.city}", postal: "${this.state.postal}", contact: "${this.state.contact}", ${products}, ${menu} }`;
        const { services } = this.context;

        services.ordersApi.add(data)
            .then(({ createOrder }) => {
                if (createOrder && createOrder.id) {
                    this.setState({ loading: false, step: 2 });
                } else console.log("Error creating Order");
            }).catch(error => console.log(error));
    }

    handleChange = (event) => {
        const { name, value } = event.target
        this.setState({ [name]: value });
    }

    handleSteps(id, value) {
        const { materials } = this.state;
        const { group } = materials[id];

        materials[id].value = value;

        for (var key in materials) {
            if (materials[key].group == group && key != id) {
                if (value) {
                    materials[key].active = false;
                    materials[key].value = 0;
                } else materials[key].active = true;
            }
        }

        this.setState({ materials });
    }

    checkStep = () => {
        const { step, materials, data, loading, errors } = this.state;
        const { t } = this.props;

        switch (step) {
            case 0:
                return (
                    <div className="select-materials">
                        <div className="add-menu-add">
                            <h2>{t('admin_publish_menu.txt_select_materials', 'Escolha os materiais de visibilidade')}</h2>
                        </div>
                        <div className="items m-top-8">
                            <div className="row m-top-4">
                                {data.allMaterials.map(material => (
                                    <div className="item" key={material.id}>
                                        <div className="image">
                                            <img src={materials[material.id].image.publicUrl} alt={material.name}/>
                                        </div>
                                        <h2 className="description">{material.name}</h2>
                                        <SpecialStepper id={material.id} max={materials[material.id].max} onValueChange={this.handleSteps} value={materials[material.id].value} active={materials[material.id].active}/>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {this.checkOrder()}
                    </div>
                );
                break;
            case 1:
                return (
                    <div className={loading ? "add-menu loading" : "add-menu"}>
                        <div className="add-menu-add">
                            <h2>{t('admin_publish_menu.txt_fill_form', 'Preencha os dados de entrega')}</h2> 
                            <form autoComplete="off">
                                <input type="text" placeholder="Morada" name="address" onChange={this.handleChange} value={this.state.address} />
                                {errors.address ? <div className="error">{errors.address}</div> : null}
                                <input type="text" placeholder="Código Postal" name="postal" onChange={this.handleChange} value={this.state.postal} className="m-top-4" />
                                {errors.postal ? <div className="error">{errors.postal}</div> : null}
                                <input type="text" placeholder="Localidade" name="city" onChange={this.handleChange} value={this.state.city} className="m-top-4"/>
                                {errors.city ? <div className="error">{errors.city}</div> : null}
                                <input type="text" placeholder="Nome do responsável" name="name" onChange={this.handleChange} value={this.state.name} className="m-top-4"/>
                                {errors.name ? <div className="error">{errors.name}</div> : null}
                                <input type="text" placeholder="Contacto" name="contact" onChange={this.handleChange} value={this.state.contact} className="m-top-4"/>
                                {errors.contact ? <div className="error">{errors.contact}</div> : null}
                            </form>
                            <div className="m-top-8">
                                <div onClick={this.handleSubmit} className="btn-primary">
                                    {t('btn.order_materials', 'Encomendar materiais')}
                                </div>
                            </div>
                        </div>
                    </div>
                );
                break;
            case 2:
                return (
                    <div className="add-menu">
                        <div className="add-menu-add">
                            <h2>Obrigado pela sua encomenda. Vai receber no seu estabelecimento num prazo máximo de 5 dias úteis.</h2>
                            <div className="m-top-8">
                                <div onClick={this.onPublished} className="btn-primary">
                                    {t('btn.close', 'Fechar')}
                                </div>
                            </div>
                        </div>
                    </div>
                );
                break;
        }
    }

    checkOrder = () => {
        const { materials } = this.state;
        const { t } = this.props;

        let ordering = false;

        for (var key in materials) {
            if (materials[key].value) {
                ordering = true;
                break;
            }
        }

        if (ordering) {
            return (
                <div className="m-top-8">
                    <div onClick={this.handleNext} className="btn-primary">
                        {t('btn.order_materials', 'Encomendar materiais')}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="m-top-8">
                    <div onClick={this.handlePublish} className="btn-primary">
                        {t('btn.no_materials', 'Não quero materiais')}
                    </div>
                </div>
            );
        }
    }

    render() {
        const { loading } = this.state;

        if (!loading) {
            return (
                <div className="publish-menu">
                    {this.checkStep()}
                </div>
            );
        } else {
            return (
                <div className="add-menu loading"></div>
            );
        }
    }
}

PublishMenuContent.contextType = ServicesContext;

export default withTranslation()(PublishMenuContent);
