import React, { Component } from 'react'
import "./AddTabContent.scss";
import { ServicesContext } from '../../../context/services';
import { withTranslation  } from 'react-i18next';
import services from '../../../services';

class EditUserContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            user: props.data.user,
            code: '',
            name: '',
            nif: '',
            country: '',
            errors: {}
        };

        this.onComplete = props.onComplete;
        this.onDelete = props.data.onDelete;
        this.deleteUser = this.deleteUser.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onLogOut = this.onLogOut.bind(this);
    }

    componentDidMount() {
        const id = this.state.user.id;
        const { services } = this.context;

        services.usersApi.getById(id)
            .then(({ User }) => {
                if (User) {
                    const name = User.name;
                    const code = User.code || '';
                    const nif = User.nif || '';
                    const country = User.country || '';

                    this.setState({ name, code, nif, country, loading: false });
                } else {
                    localStorage.removeItem(process.env.REACT_APP_VERSION);
                    window.location.reload();
                }
            });
    }

    validate() {
        let errors = {};
        let { name, nif, code } = this.state;
        const { t } = this.props;

        const isValidName = name.length < 3 ? null : true;
        errors.name = (isValidName ? null : t('error_msg.company_name', 'Nome de empresa inválido!'));
        
        let isValidNif = true, 
        isValidCode = true;

        if(process.env.REACT_APP_VERSION != "sb:user:en"){
            isValidNif = !/^\d+$/.test(nif) || nif.length !== 9 ? null : true;
            errors.nif = (isValidNif ? null : t('error_msg.vat_number', 'NIF inválido!'));
            
            isValidCode = !/^\d+$/.test(code) || code.length !== 10 ? null : true;
            errors.code = (isValidCode ? null : t('error_msg.client_code', 'Codigo inválido!'));
        }


        this.setState({ errors });

        return isValidName && isValidNif && isValidCode;
    }

    handleSubmit = (event) => {
        event.preventDefault();
        console.log("VERIFY VALIDATIONS");
        
        if (!this.validate()) return;

        this.setState({ loading: true });

        const { services } = this.context;
        const { name, nif, code, country, user: { id } } = this.state;

        services.usersApi.update({
            name,
            nif,
            code,
            country,
            id
        }).then(({ updateUser }) => {
            if (updateUser && updateUser.id) {
                this.onComplete();
            } else console.log('Error updating user');
        }).catch(error => console.log(error));
    }

    handleChange = (event) => {
        const { name, value } = event.target
        this.setState({ [name]: value.replace('"', '') });
    }

    deleteUser = (event) => {
        event.preventDefault();
        this.onDelete();
    }

    onLogOut = (event) => {
        event.preventDefault();
        services.authApi.logout().then(() => {
            localStorage.removeItem(process.env.REACT_APP_VERSION);
            window.location.reload();
        });
    }

    render() {
        const { loading, errors } = this.state;
        const { t } = this.props;

        return (
            <div className={loading ? "add-menu loading" : "add-menu"}>
                <h2>{t('admin_edit_account.title', 'Editar conta')}</h2>
                <form autoComplete="off">
                    <input type="text" placeholder={t('placeholder.company_name', "Nome da Empresa")} name="name" onChange={this.handleChange} value={this.state.name} className="" />
                    {errors.name ? <div className="error">{errors.name}</div> : null}
                    {process.env.REACT_APP_VERSION != 'sb:user:en' ? <input type="text" placeholder={t('placeholder.vat_number', "NIF")} name="nif" onChange={this.handleChange} value={this.state.nif} className="m-top-4" /> : null }
                    {errors.nif ? <div className="error">{errors.nif}</div> : null}
                    {process.env.REACT_APP_VERSION != 'sb:user:en' ? <input type="text" placeholder={t('placeholder.client_code', "Código de Cliente")} name="code" onChange={this.handleChange} value={this.state.code} className="m-top-4" /> : null }
                    {errors.code ? <div className="error">{errors.code}</div> : null}
                    {/* {process.env.REACT_APP_VERSION === 'sb:user:en' ? <select name="country" id="country" value={this.state.country} 
                        onChange={this.handleChange} className="m-top-4">
                        <option value="">Select country</option>
                        <option value="South Africa">South Africa</option>
                        <option value="USA">United States of America</option>
                        <option value="Vietnam">Vietnam</option>
                    </select> : null} */}
                </form>
                <div className="m-top-8">
                    <div onClick={this.handleSubmit} className="btn-primary">
                        {t('btn.save', 'Guardar')}
                    </div>
                </div>
                <div className="m-top-4">
                    <div onClick={this.deleteUser} className="btn-tertiary">
                        {t('btn.delete_account', 'Apagar Conta')}
                    </div>
                </div>
                <div className="m-top-4">
                    <div onClick={this.onLogOut} className="btn-tertiary">
                        {t('btn.logout', 'Logout')}
                    </div>
                </div>
                <div className="m-top-8">
                    <div className="txt-center">
                        <a href={t('admin_signup.url_privacy_policy', "/pdf/politica-de-privacidade.pdf")} target="_blank" className="link">{t('admin_signup.label_privacy_policy', "Política de privacidade")}</a>
                    </div>
                    <div className="txt-center m-top-4">
                        <a href={t('admin_signup.url_terms_and_conditions', "/pdf/termos-e-condicoes.pdf")} target="_blank" className="link">{t('admin_signup.label_terms_and_condtions', "Termos e Condições")}</a>
                    </div>
                </div>
            </div>
        )
    }
}

EditUserContent.contextType = ServicesContext;

export default  withTranslation()(EditUserContent);