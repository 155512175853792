export default function makeLabelsApi({
	query,
	mutation,
}) {
	return {
		update: label => query(`
			mutation {
				updateLabel(id: "${label.id}", data: {
					pt: "${label.pt}",
					en: "${label.en}"
				}) { id }
			}
		`),
		updateMany: (items) => mutation(`
            mutation {
                updateLabels(data: [${items}] ) { id }
            }
        `),
	};
}