export default function makeUsersApi({
	query,
	mutation,
}) {
	const userFragment = `{
            id,
            name,
            nif,
            code
        }
	`;
	return {
        add: data => mutation(`
            mutation {
                createUser(data: { ${data} }) { id }
            }
        `),
        update: user => mutation(`
            mutation {
                updateUser(id: "${user.id}", data: {
                    name: "${user.name}",
                    nif: "${user.nif}",
                    code: "${user.code}",
                    country: "${user.country}",
                }) { id }
            }
        `),
        delete: userId => mutation(`
            mutation {
                deleteUser(id:"${userId}") { id }
            }
        `),
		getById: id => query(`
			query {
				User(where: { id: "${id}" }) ${userFragment}
			}
        `),
        getByEmail: email => query(`
            query {
                allUsers(where: { email: "${email}" }) { id }
            }
        `),
        validateUserById: id => mutation(`
            mutation {
                updateUser(id: "${id}", data: {
                    email_validated: true
                }) { id }
            }
        `),
	};
}