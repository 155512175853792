import React from 'react';
import '../menu/MenuItems.scss';

const MenuItems = ({ data, style, language, currency, show, shortcut, onSelected, t }) => {

  const onShortcutClick = (e) => {
    e.preventDefault();
    onSelected(shortcut.id);
  };

  const hasThumbnail = (data) => {
    let show = false;
    for (let i = 0; i < data.length; i++) {
      if (data[i].image && data[i].image !== 'null') show = true;
    }
    return show;
  }

  const sortByOrder = (items) => {
    items.sort(function (a, b) {
      if(a.order === null){
          let max = items.length;
          if(a.order > items.length || b.order > items.length) max = (a.order < b.order ? b.order : a.order) + 1;
          a.order = max;
      } 
      return a.order - b.order;
    });
    return items;
  }

  const removeNotVisible = items => {
    return items.filter(item => item.visible === true || item.visible === null);
  }

  const render = () => {
    if (data && data.ItemList) {
      const filteredItems = removeNotVisible(data.ItemList.items);
      const items = sortByOrder(filteredItems);
      const showThumbnail = hasThumbnail(filteredItems);
      
      return (
        <div className="list">
          <div className="title" style={style ? style.buttons.background : {}}>
            <img className="sep-icon" src={data.ItemList.icon.file.publicUrl ? data.ItemList.icon.file.publicUrl : ""} alt=""  style={{filter: style && style.isDarkMode ? 'invert(0.7)' : 'invert(0)' }}/>
            <h2 style={style ? style.text : {}}>{data.ItemList.name[language]}</h2>
          </div>
          <div className="content">
            <ul>
              {items.length > 0 ? items.map(item => (
                <li key={item.id}>
                  <div className={showThumbnail ? 'item thumb' : 'item no-thumb'}>
                    {showThumbnail ? <div className="thumb"><figure>{item.image && item.image != 'null' ? <img src={item.image}/> : ""}</figure></div> : ''}
                    <div className={item.items.length > 0 ? "details full" : "details"}>
                      <div className="plate">
                        <h3>{item.name[language] ? item.name[language] : ''}</h3>
                        {item.description[language] ? <p>{item.description[language]}</p>: null}
                      </div>
                      {
                        item.items.length == 0 ?
                          <div className="price" style={style ? style.buttons.color : {}}>
                            <h4>{currency} {item.price}</h4>
                          </div> : null
                      }
                      {
                        item.items.length !== 0 ?
                        <ul className="multi">
                          {item.items.map(sub => (
                            <li className="product-item" key={sub.id}>
                              <div className="product-label">{sub.name[language]}</div>
                              <div className="product-price" style={style.buttons.color}>{currency} {sub.price}</div>
                            </li>
                          ))}
                        </ul> : null
                      }
                    </div>
                  </div>
                </li>
                ))
                : 
                  <li className="no-products">{language != "en" ? t('menu.txt_no_products', 'Sem produtos') : "No products to show"}</li>
                }
            </ul>
            {show && shortcut && data.ItemList.id !== shortcut.id &&
              <nav className="buttons">
                <a onClick={onShortcutClick} style={style ? style.buttons.border : {}}>
                  <span className="icon-drinks" style={style ? style.buttons.color : {}}></span>
                  <span style={style ? style.buttons.color : {}}>{shortcut.name[language]}</span>
                </a>
              </nav>
            }
          </div>
          <footer></footer>
        </div>
      );
    } else {
      return (
        <div className="loader">
          <img src="/images/loader.gif" alt="" />
        </div>
      );
    }
  }

  return render();
}

export default MenuItems;
