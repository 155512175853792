import React, { Component } from 'react'
import "./AppUpdatesContent.scss";
import Carousel from 'react-elastic-carousel';

class AppUpdatesContent extends Component {

    constructor(props) {
        super(props);

        this.onComplete = this.props.onComplete;

        this.state = {
            APP_UPD_POPUP: props.data.APP_UPD_POPUP,
        }
    }

    render() {
        return (
            <div className="app-updates">
                <p>O Menu Autêntico foi atualizado.</p>
                <p>Veja as novidades.</p>
                <Carousel showArrows={false} pagination={true} enableSwipe={true} enableMouseSwipe={true} >
                    <div className="update-item">
                        <figure>
                            <img src="/images/app-updates/app-upd-1.png" alt="Personalização"/>
                        </figure>
                        <div className="item-txt">
                            <p className="item-tit">Personalização</p>
                            <p className="item-desc">Agora já pode customizar a imagem do seu Menu.</p>
                        </div>
                    </div>
                    <div className="update-item">
                        <figure>
                            <img src="/images/app-updates/app-upd-2.png" alt="Imagem de fundo e capa"/>
                        </figure>
                        <div className="item-txt">
                            <p className="item-tit">Imagem de fundo e capa</p>
                            <p className="item-desc">Para ter o seu Menu com as cores e imagens que deseja basta selecionar a imagem, cor ou textura.</p>
                        </div>
                    </div>
                    <div className="update-item">
                        <figure>
                            <img src="/images/app-updates/app-upd-3.png" alt="Cor dos separadores"/>
                        </figure>
                        <div className="item-txt">
                            <p className="item-tit">Cor dos separadores</p>
                            <p className="item-desc">Pode também escolher a cor dos separadores, assim como a cor do texto.</p>
                        </div>
                    </div>
                    <div className="update-item">
                        <figure>
                            <img src="/images/app-updates/app-upd-4.png" alt="Produtos Super Bock"/>
                        </figure>
                        <div className="item-txt">
                            <p className="item-tit">Produtos Super Bock</p>
                            <p className="item-desc">Adicionar produtos Super Bock agora é muito mais simples. Basta selecionar da lista de produtos pré-carregados.</p>
                        </div>
                    </div>
                </Carousel>
                   
                <div className="buttons-bottom">
                    <a href="#" className="btn-brown" onClick={this.onComplete}>Começar</a>
                </div>
            </div>
        )
    }
}

export default AppUpdatesContent;