import React, { Component } from 'react';
import {sortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { ServicesContext } from '../../../context/services';
import { withTranslation } from 'react-i18next';
import "./SortContent.scss";

const SortableItem = SortableElement(({value}) => <div className="list-tab-item">{process.env.REACT_APP_VERSION != 'sb:user:en' ? value.name.pt : value.name.en}</div>);

const SortableContainer = sortableContainer(({children}) => {
  return <div className="list-tabs">{children}</div>;
});

class SortDetailContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            list: props.data.list
        };

        this.onSave = props.data.onSave;
        this.saveChanges = this.saveChanges.bind(this); 
    }

    saveChanges = (event) => {
        event.preventDefault();
        
        const { services } = this.context;
        const { list } = this.state;

        let items = '';

        for(let i = 0 ; i < list.length; i++) {
            if(items.length) items += ',';
            items += `{ id: "${list[i].id}", data: { order: ${i} } }`;
        }

        services.itemsApi.updateMany(items)
            .then(({ updateItems }) => {
                if (updateItems) {
                    this.onSave();
                } else console.log("Error updating the Item");
            }).catch(error => console.log(error));
    }
    
    onSortEnd = ({oldIndex, newIndex}) => {
        this.setState(({list}) => ({
            list: arrayMove(list, oldIndex, newIndex),
        }));
    };

    render() {
        const {list} = this.state;
        const { t } = this.props;
        return ( 
            <div className="sort-tabs">
                <h2>{t('admin_sort_product.title', 'Ordenar produtos')}</h2>
                <SortableContainer onSortEnd={this.onSortEnd}>
                    {list.map((value, index) => (
                        <SortableItem key={value.id} index={index} value={value} />
                    ))}
                </SortableContainer>
                <div className="m-top-8">
                    <a onClick={this.saveChanges} className="btn-primary">
                        {t('btn.save', 'Guardar')}
                    </a>
                </div>
            </div>
        )
    }
}

SortDetailContent.contextType = ServicesContext;

export default withTranslation()(SortDetailContent);
