export default function makeItemApi({
	query,
	mutation,
}) {
	const itemFragment = `{
        id,
        order,
        name {
            id,
            pt,
            en
        },
        description {
            id,
            pt,
            en
        },
        items {
            id,
            name {
                pt,
                en
            },
            price
        },
        image,
        price,
        lists {
            id
        },
        visible
    }`;

	return {
        delete: id => mutation(`
            mutation {
                deleteItem(id: "${id}") { id }
            }
        `),
        update: (id, data) => mutation(`
            mutation {
                updateItem(id: "${id}", data: ${data} ) { id }
            }
        `),
        updateMany: (items) => mutation(`
            mutation {
                updateItems(data: [${items}] ) { id }
            }
        `),
        add: data => mutation(`
            mutation {
                createItem(data: ${data} ) { id }
            }
        `),
        getById: id => query(`
            query {
                Item(where: { id: "${id}" }) ${itemFragment}
            }
        `),
        // getByList: lid => query(`
		// 	query {
        //         allItems(where: { lists: { id: "${lid}" } }, sortBy: order_ASC) ${itemFragment}
		// 	}
        // `),
        getByList: lid => query(`
			query {
                allItems(where: { lists: { id: "${lid}" } }) ${itemFragment}
			}
        `),
	};
}