export default function makeOrdersApi({
    query,
    mutation,
}) {
    const orderFragment = `{
        id,
        name,
        group,
        stock,
        max,
        image {
            publicUrl
        }
    }`;

    const reportFragment = `{
        id,
        order,
        product_1 { name },
        quantity_1,
        product_2 { name },
        quantity_2,
        product_3 { name },
        quantity_3,
        name,
        address,
        contact,
        postal,
        city,
        menu { id, qrcode, restaurant, user { name } },
        createdAt,
        sent
    }`;

    return {
        add: order => mutation(`
            mutation {
                createOrder(data: ${order} ) { id }
            }
        `),
        update: order => mutation(`
            mutation {
                updateOrder(id: "${order.id}", data: {
                    sent: ${order.sent}
                }) { id }
            }
        `),
        getReport: () => query(`
			query {
				allOrders ${reportFragment}
			}
        `),
        getAllByUserId: userId => query(`
			query {
				allOrders(where: { client: { id: "${userId}" } }) { id }
			}
        `),
    };
}