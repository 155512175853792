export default function makeItemApi({
	query,
	mutation,
}) {
	const itemFragment = `{
        id,
        name {
            id,
            pt,
            en
        },
        price
    }`;

	return {
        delete: id => mutation(`
            mutation {
                deleteSubItem(id: "${id}") { id }
            }
        `),
        update: (id, data) => mutation(`
            mutation {
                updateSubItem(id: "${id}", data: ${data} ) { id }
            }
        `),
        updateMany: (items) => mutation(`
            mutation {
                updateSubItems(data: [${items}] ) { id }
            }
        `),
        add: data => mutation(`
            mutation {
                createSubItem(data: ${data} ) ${itemFragment}
            }
        `),
        addMany: data => mutation(`
            mutation {
                createSubItems(data: [${data}] ) { id }
            }
        `),
        getById: id => query(`
            query {
                SubItem(where: { id: "${id}" }) ${itemFragment}
            }
        `),
        getByItem: id => query(`
			query {
                allSubItems(where: { item: { id: "${id}" } }) ${itemFragment}
			}
        `),
	};
}