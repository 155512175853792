import React, { Component } from 'react';
import './Icons.scss';
import { ServicesContext } from '../../../context/services';
import ReactDOM from "react-dom";   

import Carousel from 'react-elastic-carousel';

const ITEMS_PER_SLIDE = 8;

class Icons extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            loading: true,
            selected: props.selected,
            style: props.style
        };
       
        this.myRef = React.createRef();
    }

    handleClick = id => {
        this.setState({ selected: id });
        this.props.onChange(id);
    }

    setValue = (value) => {
        // console.log(value);
    }

    componentWillUpdate(nextProps) {
        let { selected } = this.state;
        if (selected !== nextProps.selected) {
            this.setState({ selected: nextProps.selected });
        }
    }
    componentDidMount() {
        const { services } = this.context;

        services.iconsApi.getAll()
        .then(({allIcons}) => {
            this.setState({ loading: false, data: { allIcons } })
            /*
            const selectedIndex = allIcons.findIndex(item => item.id ===this.state.selected);
            const initIndex = Math.floor((selectedIndex)/ITEMS_PER_SLIDE);
            if(this.state.selected) this.setCarouselPos(initIndex);
            */
        })
        .catch(err => {
            this.setState({ loading: false });
        });
    }

    componentDidUpdate(){
        if(this.state.style){
            const dots = document.body.getElementsByClassName('rec-dot');
            let i;
            if(dots.length > 0){
                for (i = 0; i < dots.length; i++) {
                    dots.item(i).style.backgroundColor = this.state.style.buttons.color['color'];
                }
            }
        }
    }

    setCarouselPos(pos){
        if(this.carousel) this.carousel.goTo(Number(pos));
    }

    chunkArray(myArray, chunk_size){

        var results = [],
            tmpArray = myArray.concat();
            
        while (tmpArray.length) {
            results.push(tmpArray.splice(0, chunk_size));
        }
        return results;
    }

    render() {
        const { data, loading, size, selected, style } = this.state;
        if (data && data.allIcons) {
            const pages = this.chunkArray(data.allIcons, ITEMS_PER_SLIDE);
            const pagesHtml = pages.map((page, index) => {
                return (
                    <ul key={index} className="page">
                         {page.map(item => {
                            return (
                                <li key={item.id}>
                                    <a href="#" onClick={(event) => this.handleClick(item.id)} className={item.id == this.state.selected ? "selected" : ""} style={item.id == this.state.selected && style ? style.buttons.background : null}>
                                        <div className={item.id == this.state.selected ? "icon-button selected" : "icon-button"}>
                                            <img className="icon" src={item.file.publicUrl} />
                                        </div>
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                )
            });

            const selectedIndex = data.allIcons.findIndex(item => item.id ===this.state.selected);
            const initIndex = Math.floor((selectedIndex)/ITEMS_PER_SLIDE);
            if(this.state.selected) this.setCarouselPos(initIndex);
                
            return (
                <div className="m-top-4" ref={this.myRef}>
                    <ul className="admin-icons">
                        <Carousel ref={ref => (this.carousel = ref)} showArrows={false} pagination={pages.length > 1 ? true : false} enableSwipe={true} enableMouseSwipe={true} >{pagesHtml}</Carousel>
                    </ul>
                </div>
            );
        }
        else {
            return (
                <div className="app">
                    <p>Loading...</p>
                </div>
            );
        }
    }
}

Icons.contextType = ServicesContext;

export default Icons;