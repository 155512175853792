import 'react-app-polyfill/ie11';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './styles/main.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

import i18n from "./config/i18n";

import HttpsRedirect from 'react-https-redirect';

ReactDOM.render( 
  <HttpsRedirect>
    <React.StrictMode>
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </React.StrictMode>
  </HttpsRedirect>,
  document.getElementById('root')
);

serviceWorker.unregister(); 