import React, { useState } from "react"
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import PrivateRoute from './PrivateRoute';
import { AuthContext } from "./context/auth";

import services from './services'; 
import { ServicesContext } from './context/services'; 

import Menu from './components/menu/Menu';

import Admin from './components/admin/Admin';
import AdminMenu from './components/admin/AdminMenu';
import AdminSubTab from './components/admin/AdminSubTab';
import AdminDetail from './components/admin/AdminDetail';

import Login from "./components/admin/Login";
import Signup from './components/admin/Signup';
import Confirm from './components/admin/Confirm';
import ForgotPassword from './components/admin/ForgotPassword';
import RecoverPassword from './components/admin/RecoverPassword';

import Orders from './components/data/Orders';
import Menus from './components/data/Menus';
import LandingPage from "./components/LandingPage";

// import NotFoundPage from './components/NotFoundPage';

function App(props) {
  const existingTokens = JSON.parse(localStorage.getItem(process.env.REACT_APP_VERSION));
  const [authTokens, setAuthTokens] = useState(existingTokens);

  const isLoggedIn = () => {
    const existingUser = localStorage.getItem(process.env.REACT_APP_VERSION);
    return existingUser !== null;
  };

  const setTokens = (data) => {
    localStorage.setItem(process.env.REACT_APP_VERSION, JSON.stringify(data));
    setAuthTokens(data);
  }

  return (
    <ServicesContext.Provider value={{ services }}>
      <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens, isLoggedIn }}>
        <Router>
          <Switch>
            <Route path="/menu/:id" component={Menu} />
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            <Route path="/confirm/:id" component={Confirm} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/recover-password/:id" component={RecoverPassword} />
            <PrivateRoute exact path="/admin" component={Admin} />
            <PrivateRoute path="/admin/menu/:id" component={AdminMenu} />
            <PrivateRoute path="/admin/tab/:id" component={AdminSubTab} />
            <PrivateRoute path="/admin/detail/:id" component={AdminDetail} />
            <PrivateRoute path="/data/orders" component={Orders} />
            <PrivateRoute path="/data/menus" component={Menus} />
            <Route path="/" component={LandingPage} />
            <Redirect to="/admin" />
          </Switch> 
        </Router>
      </AuthContext.Provider>
    </ServicesContext.Provider>
  );
}

export default App; 