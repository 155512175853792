export default function makeMaterialsApi({
	query,
	mutation,
}) {
	const materialFragment = `{
        id,
        name,
        group,
        stock,
        max,
        image {
            publicUrl
        }
    }`;

	return {
        getAll: () => query(`
            query {
                allMaterials ${materialFragment}
            }
        `),
	};
}