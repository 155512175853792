import React, { Component } from "react";
// import Carousel from 'react-elastic-carousel';
import "./AdminMenu.scss";
import { ServicesContext } from "../../context/services";
import { withTranslation } from 'react-i18next';

import Styles from "../utils/styles";
import Popup from "./popups/Popup";
import Tabs from "./partials/Tabs";

class AdminMenu extends Component {
  constructor(props) {
    super(props);

    this.id = this.props.match.params.id;

    this.state = {
      popup: null,
      data: null
    };

    this.confirmDelete = this.confirmDelete.bind(this);
    this.publishMenu = this.publishMenu.bind(this);
    this.showQRCode = this.showQRCode.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.deleteTab = this.deleteTab.bind(this);
    this.editMenu = this.editMenu.bind(this);
    this.editTab = this.editTab.bind(this);
    this.addTab = this.addTab.bind(this);
    this.sortTabs = this.sortTabs.bind(this);

    this.onTheme = this.onTheme.bind(this);
    this.onResetTheme = this.onResetTheme.bind(this);
  }

  componentDidMount() {
    const id = this.id;
    const { services } = this.context;

    services.menusApi
      .getById(id)
      .then(({ Menu }) => this.setState({ data: { Menu } }));
  }

  editMenu(e) {
    e.preventDefault();
    this.setState({ popup: { name: "edit_menu", id: this.id } });
  }

  addTab(e) {
    e.preventDefault();
    const show_english = this.state.data && this.state.data.Menu.show_english;
    this.setState({ popup: { name: "add_tab", menu: this.id, show_english } });
  }

  onTheme(e) {
    e.preventDefault();
    this.setState({ popup: { name: 'edit_theme', menu: this.state.data.Menu, onReset:this.onResetTheme } });
  }

  onResetTheme(data) {
    this.setState({ popup: { name: 'reset_theme', settings: data } });
  }

  editTab(e) {
    e.preventDefault();
    const { currentTarget } = e;
    const show_english = this.state.data && this.state.data.Menu.show_english;
    this.setState({
      popup: {
        name: "edit_tab",
        id: currentTarget.dataset.id,
        show_english,
        onDelete: this.confirmDelete,
      },
    });
  }

  sortTabs(list) {
    this.setState({ popup: { name: "sort_tabs", list: list, onSave: this.closePopup } });
  }

  publishMenu(e) {
    e.preventDefault();

    this.setState({
      popup: {
        userId: this.state.data.Menu.user.id,
        onPublished: this.showQRCode,
        name: "publish_menu",
        id: this.id,
      }
    });

  }

  showQRCode() {
    const url = this.state.data.Menu.url;
    const image = this.state.data.Menu.qrcode;
    this.setState({ popup: { name: "qr_code", image, url } });
  }

  closePopup(e) {
    if (e) e.preventDefault();
    this.setState({ popup: null, data: null });
    this.componentDidMount();
  }

  confirmDelete(id) {
    this.setState({
      popup: { name: "delete", id: id, onDelete: this.deleteTab },
    });
  }

  deleteTab(id) {
    const { services } = this.context;

    services.itemListApi
      .delete(id)
      .then(({ deleteItemList }) => {
        if (deleteItemList && deleteItemList.id) {
          this.closePopup();
        } else console.log("Error deleting ItemList");
      })
      .catch((error) => console.log(error));
  }

  checkLogo(menu,style) {
    if (menu.logo && menu.logo !== "null") {
      return <img className="logo" src={menu.logo} alt={menu.restaurant} />;
    } else {
      return <h1 style={style.text}>{menu.restaurant}</h1>;
    }
  }

  checkPublished(menu, publish, order_materials, style) {
    if (!menu.published || !menu.order) {
      return (
        <div className="footer-buttons m-top-4">
          <div onClick={this.publishMenu} className="btn-secundary" style={style.border}>
            <span>{!menu.published || process.env.REACT_APP_VERSION === "sb:user:en" ? publish : order_materials}</span>
          </div>
        </div>
      );
    }
  }


  render() {
    const { data, popup } = this.state;
    const { t } = this.props;

    if (data && data.Menu) {
      const style = Styles.checkStyle(data.Menu);

      return (
        <div className="menu" style={ style.background.backgroundColor || style.background.backgroundImage ? {backgroundColor:'#eee'} : null }>
          <div className="app opened" style={style.background}>
            <header className="main-header">
              <div className="top-header">
                <div className="button">
                  <a className="back" href="/admin">
                    <span className="icon-arrow-back" style={style ? style.customText : {}}></span>
                  </a>
                </div>

                <div className="button theme" onClick={this.onTheme} style={style ? style.buttons.background : {}}>
                    <span className="icon-painting" style={style.text}></span>
                </div>
              </div>

              <div className="cover">
                <div className="image" style={style.cover}></div>
                <div className="product" style={style.backgroundProduct}></div>
              </div>

              <div className="placeholder editable" style={{borderColor: style.isDarkMode ? 'rgba(92, 92, 92, 0.3)' : 'rgba(255, 255, 255, 0.3)' }}>
                {this.checkLogo(data.Menu, style)}
                <div className="edit" onClick={this.editMenu}>
                  <span className="icon-edit" style={style.customText}></span>
                </div>
              </div>
            </header>

            <Tabs menu={this.id} style={style} addTab={this.addTab} editTab={this.editTab} sortTabs={this.sortTabs} subTab={false}></Tabs>

            {this.checkPublished(data.Menu, t('btn.publish', 'Publicar'), t('btn.order_materials', 'Encomendar Materiais'), style.buttons)}

            <div className="m-bottom-10"></div>

            <Popup data={popup} onClose={this.closePopup} style={style}/>
          </div>
        </div>
      );
    } else {
      return (
        <div className="loader">
          <img src="/images/loader.gif" alt="" />
        </div>
      );
    }
  }
}

AdminMenu.contextType = ServicesContext;

export default withTranslation()(AdminMenu);
