import React from 'react';
import './Popup.scss';

import EditUserContent from './EditUserContent';
import AddMenuContent from './AddMenuContent';
import EditMenuContent from './EditMenuContent';
import PublishMenuContent from './PublishMenuContent';
import AddTabContent from './AddTabContent';
import EditTabContent from './EditTabContent';
import SortTabsContent from './SortTabsContent';
import AddItemContent from './AddItemContent';
import EditItemContent from './EditItemContent';
import QRCodeContent from './QRCodeContent';
import DeleteContent from './DeleteContent';
import SortDetailContent from './SortDetailContent';
import AppUpdatesContent from './AppUpdatesContent';
import EditThemeContent from './EditThemeContent';
import SelectItemContent from './SelectItemContent';
import PickItemContent from './PickItemContent';
import AddSubItemsQuestion from './AddSubItemsQuestion';
import ResetThemeContent from './ResetThemeContent';
import ChangeTabContent from './ChangeTabContent';

const Popup = ({ data, onClose, style }) => {
  const render = () => {
    const layout = () => {
      if(style) data.style = style;
      switch (data.name) {
        case "edit_user": return <EditUserContent data={data} onComplete={onClose} />;
        case "add_menu": return <AddMenuContent data={data} onComplete={onClose} />;
        case "edit_menu": return <EditMenuContent data={data} onComplete={onClose} />;
        case "publish_menu": return <PublishMenuContent data={data} onComplete={onClose} />;
        case "add_tab": return <AddTabContent data={data} onComplete={onClose} />;
        case "edit_tab": return <EditTabContent data={data} onComplete={onClose} />;
        case "sort_tabs": return <SortTabsContent data={data} onComplete={onClose} />;
        case "select_item": return <SelectItemContent data={data} onComplete={onClose} />;
        case "pick_item": return <PickItemContent data={data} onComplete={onClose} />;
        case "add_item": return <AddItemContent data={data} onComplete={onClose} />;
        case "add_sub_item_question": return <AddSubItemsQuestion data={data} onComplete={onClose} />;
        case "edit_item": return <EditItemContent data={data} onComplete={onClose} />;
        case "qr_code": return <QRCodeContent data={data} onComplete={onClose} />;
        case "delete": return <DeleteContent data={data} onComplete={onClose} />;
        case "sort_detail": return <SortDetailContent data={data} onComplete={onClose} />;
        case "app_updates": return <AppUpdatesContent data={data} onComplete={onClose} />;
        case "edit_theme": return <EditThemeContent data={data} onComplete={onClose} />;
        case "reset_theme": return <ResetThemeContent data={data} onComplete={onClose} />;
        case "change_tab": return <ChangeTabContent data={data} onComplete={onClose} />;
      }
    }

    if (data) {
      return (
        <div className="popup">
          <div className="overflow">
            <div className="box">
              {layout()}
              <a href="#" className="close" onClick={onClose}>
                <span className="icon-close"></span>
              </a>
            </div>
          </div>
        </div>
      );
    } else return (null);
  }

  return render();
}

export default Popup;
