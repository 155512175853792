export default function makeDescriptionApi({
	query,
	mutation,
}) {
	return {
		update: label => query(`
			mutation {
				updateDescription(id: "${label.id}", data: {
					pt: "${label.pt}",
					en: "${label.en}"
				}) { id }
			}
        `),
	};
}