import React, { Component } from 'react';
import { ServicesContext } from '../../../context/services';
import { withTranslation } from 'react-i18next';
import Icons from '../partials/Icons';
import "./AddTabContent.scss";
//import v8n from "v8n";
import RadioGroup from '../partials/RadioGroup';

class AddTabContent extends Component {

    constructor(props) {
        super(props);
        
        const user = localStorage.getItem(process.env.REACT_APP_VERSION);

        this.state = {
            loading: false,
            menu: props.data.menu,
            show_english: props.data.show_english,
            errors: { name: { pt: '', en: '' } },
            name: { pt: '', en: '' },
            icon: '',
            sub_tab: props.data.parent ? false : null,
            parent: props.data.parent,
            hide_tab: false,
            user: JSON.parse(user),
        };

        this.onComplete = props.onComplete;
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleLanguage = this.handleLanguage.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
    }

    validate() {
        let errors = {};
        let { name, icon, show_english, user } = this.state;
        const { t } = this.props;

        errors.name = {};
        let isValidNamePT = true;
        if(process.env.REACT_APP_VERSION === 'sb:user' || show_english){
            isValidNamePT = name.pt.length == 0 ? null : true;
            errors.name.pt = (isValidNamePT ? null : "Nome do tab inválido!");
        }

        let isValidNameEN = true;
        if (show_english) {
            isValidNameEN = name.en.length === 0 ? null : true;
            errors.name.en = (isValidNameEN ? null : "Name of the tab is invalid!");
        }
        
        const isValidIcon = icon === "" ? null : true;
        errors.icon = (isValidIcon ? null : t('error_msg.select_icon', "Tem de escolher um icon."));

        this.setState({ errors });

        return isValidNamePT && isValidNameEN && isValidIcon;
    }

    handleFocus(event) {
        const errors = this.state.errors;
        const { name, value } = event.target
        errors.name[name] = "";
        this.setState({ errors });
    }

    handleSubmit = (event) => {
        event.preventDefault();

        if (!this.validate())
            return;

        this.setState({ loading: true });

        const { services } = this.context;
        const { name, icon, menu, sub_tab, parent, hide_tab } = this.state;

        console.log(menu, parent);

        if(!parent){
            services.itemListApi.add({ name, icon, menu, sub_tab, hide_tab })
                .then(({ createItemList }) => {
                    if (createItemList && createItemList.id) {
                        this.onComplete();
                    } else console.log("Error creating the ItemList");
                }).catch(error => console.log(error));
        }else{
            services.itemListApi.addSub({ name, icon, parent, menu, hide_tab })
                .then(({ createItemList }) => {
                    if (createItemList && createItemList.id) {
                        this.onComplete();
                    } else console.log("Error creating the ItemList");
                }).catch(error => console.log(error));
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target
        this.setState({ [name]: value.replace('"', '') }); 
    }

    handleLanguage = (event) => {
        const nameObj = this.state.name;
        const { name, value } = event.target

        nameObj[name] = value.replace('"', '');

        this.setState({ name: nameObj });
    }

    onIconChange = (id) => {
        const errors = this.state.errors;
        errors.icon = "";
        this.setState({ icon: id, errors });
    }    

    handleCheckbox  = (event) => {
        const target = event.target;
        const value = target.name === 'sub_tab' || target.name === 'hide_tab' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    }

    render() {
        const { errors, show_english, loading, sub_tab, hide_tab } = this.state;
        const { t } = this.props;

        return (
            <div className={loading ? "add-menu loading" : "add-menu"}>
                <h2>{t('admin_add_tab.title', 'Adicionar separador')}</h2>
                <form autoComplete="off">
                    <input
                        type="text"
                        placeholder="Nome do separador"
                        name="pt"
                        onChange={this.handleLanguage}
                        onFocus={this.handleFocus}
                        value={this.state.name.pt}
                        style={process.env.REACT_APP_VERSION === 'sb:user:en' && !this.props.data.show_english ? {display: 'none'} : null }
                    />
                    {errors.name.pt ? <div className="error">{errors.name.pt}</div> : null}
                    {show_english || process.env.REACT_APP_VERSION === 'sb:user:en' ?
                        <input
                            type="text"
                            placeholder="Name of the tab"
                            name="en"
                            onChange={this.handleLanguage}
                            onFocus={this.handleFocus}
                            value={this.state.name.en}
                            className="m-top-4"
                        /> : null}
                    {errors.name.en ? <div className="error">{errors.name.en}</div> : null}
                </form>
                <div className="m-top-8">
                    <h2>{t('admin_add_tab.txt_select_icon', 'Escolha o ícone')}</h2>
                </div>
                {!loading ? <Icons selected={this.state.icon} onChange={this.onIconChange}  /> : "" }
                {errors.icon ? <div className="error">{errors.icon}</div> : null}
                
                { !this.state.parent ? 
                    <div className="row-checkbox m-top-4">
                        <input type="checkbox" id="sub_tab" name="sub_tab" onChange={ this.handleCheckbox } checked={ sub_tab }/>
                        <label className="label-checkbox" htmlFor="sub_tab">{t('placeholder.sub_tabs', 'Usar sub-separadores')}</label>
                    </div> 
                    : ""
                } 
                <div className="row-checkbox m-top-4">
                    <input type="checkbox" id="hide_tab" name="hide_tab" onChange={ this.handleCheckbox } checked={ hide_tab }/>
                    <label className="label-checkbox" htmlFor="hide_tab">{this.state.parent ? t('placeholder.hide_sub_tab', 'Ocultar sub-separadores') : t('placeholder.hide_tab', 'Ocultar separadores')}</label>
                </div>
                <div className="m-top-4">
                    <a onClick={this.handleSubmit} className="btn-primary">
                        {t('btn.save', 'Guardar')}
                    </a>
                </div>
            </div>
        )
    }
}

AddTabContent.contextType = ServicesContext;

export default withTranslation()(AddTabContent);