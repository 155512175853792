import React, { Component } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { withTranslation } from 'react-i18next';
import "./QRCodeContent.scss";

class QRCodeContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            url: props.data.url,
            image: props.data.image
        };

        this.onComplete = props.onComplete;
    }

    handlePrint(e) {
        e.preventDefault();
        window.print();
    }

    render() {
        const { t } = this.props;
        return (
            <div className="qr-code">
                <h2>{t('admin_qrcode.title', 'QR Code')}</h2>
                <figure>
                    <QRCode value={this.state.url} fgColor="#000" size="300" logoWidth="82"  logoImage="/images/logo-superbock-bw.png" />
                </figure>
                <div className="share-button">
                    <a href={"whatsapp://send?text=" + this.state.url}>
                        <span>{t('btn.share', 'Partilhar')}</span>
                    </a>
                </div>
                <div className="m-top-8">
                    <a onClick={this.handlePrint} className="btn-primary">
                        {t('btn.print', 'Imprimir')}
                    </a>
                </div>
            </div>
        )
    }
}

export default withTranslation()(QRCodeContent);