export default function makeProductsApi({
	query,
	mutation,
}) {
	const productFragment = `{
        id,
        name {
            id,
            pt,
            en
        },
        description {
            id,
            pt,
            en
        },
        image {
            publicUrl
        }
    }`;

	return {
        getAll: () => query(`
            query {
                allProducts(sortBy: order_ASC)  ${productFragment}
            }
        `),
	};
}