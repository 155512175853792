import React, { Component } from 'react';
import './Admin.scss';
import { ServicesContext } from '../../context/services';
import { withTranslation  } from 'react-i18next';

import Popup from './popups/Popup';

const APP_UPD_POPUP = "sb:updates:2";


class Admin extends Component {
  constructor(props) {
    super(props);

    const user = localStorage.getItem(process.env.REACT_APP_VERSION)

    this.state = {
      user: JSON.parse(user),
      popup: null,
      data: null
    };

    this.confirmDeleteUser = this.confirmDeleteUser.bind(this);
    this.confirmDeleteMenu = this.confirmDeleteMenu.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.deleteMenu = this.deleteMenu.bind(this);
    this.editMenu = this.editMenu.bind(this);
    this.showCode = this.showCode.bind(this);
    this.onConfig = this.onConfig.bind(this);
    this.addMenu = this.addMenu.bind(this);
  }

  componentDidMount() {
    this.updateList();
    this.showUpdatesPopup();
  }

  updateList() {
    const { services } = this.context;
    const { user: { id } } = this.state;

    services.menusApi.getAllByUserId(id)
      .then(({ allMenus }) => {
        if (allMenus) {
          this.setState({ data: { allMenus } });
        } else {
          localStorage.removeItem(process.env.REACT_APP_VERSION);
          window.location.reload();
        }
      });
  }

  showUpdatesPopup() {
    if (!localStorage.getItem(APP_UPD_POPUP) && process.env.REACT_APP_VERSION !== 'sb:user:en') {
      this.setState({ popup: { name: 'app_updates', APP_UPD_POPUP: APP_UPD_POPUP } });
    } else this.setState({ popup: null });
  }

  closePopup(e) {
    if (e) e.preventDefault();

    if (this.state.popup.name === "app_updates") {
      localStorage.setItem(APP_UPD_POPUP, true);
    }
    
    this.setState({ popup: null, data: null });
    this.updateList();
  }

  editMenu(e) {
    e.preventDefault();

    const { currentTarget } = e;
    const id = currentTarget.dataset.id;

    this.setState({ popup: { name: 'edit_menu', id: id } });
  }

  addMenu(e) {
    e.preventDefault();
    this.setState({ popup: { name: 'add_menu', user: this.state.user.id} });
  }

  confirmDeleteMenu(e) {
    e.preventDefault();

    const { currentTarget } = e;
    const id = currentTarget.dataset.id;

    this.setState({ popup: { name: 'delete', id: id, onDelete: this.deleteMenu } });
  }

  deleteMenu(id) {
    const { services } = this.context;
    services.menusApi.delete(id)
      .then(({ deleteMenu }) => {
        if (deleteMenu && deleteMenu.id) {
          this.closePopup();
        } else console.log("Error deleting menu");
      }).catch(error => console.log(error));
  }

  confirmDeleteUser() {
    const { user: { id } } = this.state;
    this.setState({ popup: { name: 'delete', id: id, onDelete: this.deleteUser } });
  }

  deleteUser(id) {
    const { services } = this.context;
    services.usersApi.delete(id)
      .then(({ deleteUser }) => {
        if (deleteUser && deleteUser.id) {
          localStorage.removeItem(process.env.REACT_APP_VERSION);
          window.location.reload();
        } else console.log("Error deleting user");
      }).catch(error => console.log(error));
  }

  showCode(e) {
    e.preventDefault();

    const { currentTarget } = e;
    const url = currentTarget.dataset.url;
    const image = currentTarget.dataset.src;

    this.setState({ popup: { name: 'qr_code', image, url } });
  }

  onConfig(e) {
    e.preventDefault();
    this.setState({ popup: { name: 'edit_user', user: this.state.user, onDelete: this.confirmDeleteUser } });
  }

  canAddMore(menus) {
    const { t } = this.props;
    if (menus.length < 2) {
      return (
          <div className="add" onClick={this.addMenu}>
            <span>{t('btn.add_menu', 'Acrescentar Menu')}</span>
            <span className="icon-add"></span>
          </div>
      );
    }
  }

  render() {
    const { data, user, popup } = this.state;
    const { t } = this.props;
    

    if (data && data.allMenus) {
      return (
        <div className="menu">
          <div className="app admin">
          <section>
            <header className="admin-header">
              <div className="config"></div>
              <img className="menu-logo" src={t('common.logo_url', '/images/logo.svg')} alt={t('common.site_name', 'Menu Autêntico')} />
              <div className="config" onClick={this.onConfig}>
                <img src="/images/admin/icons/config.svg" width="100%" height="auto" alt="Configurar conta" />
              </div>
            </header>
            <h2 className="welcome">{t('admin_menus.txt_welcome', 'Bem vindo')},<br />{user.name}</h2>
            <div className="admin-list">
              <div className="title txt-primary">
                <h2>{t('admin_menus.title', 'Gerir Menus')}</h2>
              </div>
              <div className="content">
                <ul>
                  {data.allMenus.map(menu => (
                    <li key={menu.id}>
                      <h3><a href={"/admin/menu/" + menu.id}>{menu.name}</a></h3>
                      <nav className="actions">
                        <div data-id={menu.id} onClick={this.editMenu}>
                          <span className="icon-edit-bold"></span>
                        </div>
                        <div data-url={menu.url} data-src={menu.qrcode} onClick={this.showCode}>
                          <span className="icon-qrcode"></span>
                        </div>
                        {//todo validate conditional
                        !menu.order ? <div data-id={menu.id} onClick={this.confirmDeleteMenu}>
                          <span className="icon-trash"></span>
                        </div> : "" }
                      </nav>
                    </li>
                  ))}
                </ul>
                <div className="bottom-buttons">
                    {this.canAddMore(data.allMenus)}
                </div>
              </div>
              <footer></footer>
            </div>
          </section>
          <Popup data={popup} onClose={this.closePopup} />
        </div>
        </div>
      );
    } else {
      return (
        <div className="loader">
          <img src="/images/loader.gif" alt="" />
        </div>
      );
    }
  }
}

Admin.contextType = ServicesContext;

export default withTranslation()(Admin);