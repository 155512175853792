import React, { Component } from 'react';

import './Signup.scss';

import { ServicesContext } from '../../context/services';
import ErrorPopup from './ErrorPopup';
import RadioGroup from './partials/RadioGroup';

import { withTranslation } from 'react-i18next';

class Signup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            nif: "",
            code: "",
            country: "",
            email: "",
            password: "",
            password2: "",
            errors: {},
            terms: false,
            privacy: false,
            success: false,
            countries: null,
            genericError: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }

    componentDidMount() {
        const id = this.id;
        const { services } = this.context;

        services.countriesApi.getAll(id)
            .then(({ allCountries }) => {
                this.setState({ countries: allCountries });
            });
    }

    closePopup(e) {
        if (e) e.preventDefault();
        this.setState({ genericError: "" });
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    validate() {
        let errors = {};
        let { name, nif, code, country, email, password, password2 } = this.state;
        const { t } = this.props;

        const isValidName = name.length < 3 ? null : true;
        errors.name = (isValidName ? null : t('error_msg.company_name', 'Nome de empresa inválido!'));

        let isValidCountry = true, isValidNif = true, isValidCode = true;

        if (process.env.REACT_APP_VERSION === 'sb:user:en') {
            isValidCountry = country != "" ? true : false;
            errors.country = (isValidCountry ? null : t('error_msg.country', 'Invalid country!'));
        } else {
            isValidNif = !/^\d+$/.test(nif) || nif.length !== 9 ? null : true;
            errors.nif = (isValidNif ? null : t('error_msg.vat_number', 'NIF inválido!'));

            isValidCode = !/^\d+$/.test(code) || code.length !== 10 ? null : true;
            errors.code = (isValidCode ? null : t('error_msg.client_code', 'Codigo inválido!'));
        }

        const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isValidEmail = pattern.test(email);
        errors.email = (isValidEmail ? null : t('error_msg.email', 'Email inválido!'));

        const isValidPassword = password.length < 8 ? null : true;
        errors.password = (isValidPassword ? null : t('error_msg.password_min', 'Password inválida (minímo de 8 caracteres)!'));

        const passwordsMatch = (password === password2);

        if (isValidPassword)
            errors.password2 = (passwordsMatch ? null : t('error_msg.password_equal', 'As passwords não são iguais.'));

        this.setState({ errors });

        return isValidName && isValidNif && isValidCode && isValidCountry && isValidEmail && isValidPassword && passwordsMatch;
    }

    handleFocus(event) {
        const { name } = event.target;
        const { errors } = this.state;
        errors[name] = "";
        this.setState({ errors });
    }

    handleToggle = (event) => {
        const { t } = this.props;
        this.setState({ [event.id]: event.selected === t('common.txt_yes', "Sim") });
    }

    handleSubmit(event) {
        event.preventDefault();

        const { terms, privacy } = this.state;

        if (!this.validate())
            return;

        if (!(terms && privacy)) {
            const { t } = this.props;
            this.setState({ genericError: t('error_msg.terms_privacy', 'Tem de aceitar os termos e condições e a politica de privacidade para se registar.') });
            return;
        }

        const { services } = this.context;

        const fields = {
            name: this.state.name,
            nif: this.state.nif,
            code: this.state.code,
            email: this.state.email,
            password: this.state.password
        }

        let data = '';
        const { t } = this.props;

        for (var key in fields) {
            if (data.length) data += ',';
            data += `${key}: "${fields[key]}"`;
        }

        if (this.state.country) {
            data += `, country: { connect: { id: "${this.state.country}" } }`;
        }

        console.log(data);

        services.usersApi.add(data)
            .then(({ createUser }) => {
                if (createUser && createUser.id) {
                    this.setState({ success: true });
                } else this.setState({ genericError: t('error_msg.duplicated_account', 'Não foi possível criar a conta. Pode já existir uma conta com este e-mail, NIF ou código de cliente. Por favor contacte sbg.direto@superbockgroup.com') });
            }).catch(e => {
                this.setState({ genericError: t('error_msg.duplicated_account', 'Não foi possível criar a conta. Pode já existir uma conta com este e-mail, NIF ou código de cliente. Por favor contacte sbg.direto@superbockgroup.com') });
            });
    }

    render() {
        const { success, errors, genericError, countries } = this.state;
        const { t } = this.props;

        if (!success) {
            if (countries) {
                return (
                    <div className="menu">
                        <div className="app forms">
                        <header className="admin-header">
                            <figure className="logo">
                                <img className="admin-logo" src={t('common.logo_url', '/images/logo.svg')} alt={t('common.site_name', 'Menu Autêntico')} />
                            </figure>
                        </header>
                        <div className="content">
                            <div className="form">
                                <div className="m-bottom-6 txt-primary">
                                    <h2>{t('admin_signup.title', 'Registar')}</h2>
                                </div>
                                <form>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={this.state.name}
                                        placeholder={t('placeholder.company_name', "Nome da Empresa")}
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                    />
                                    {errors.name ? <div className="error">{errors.name}</div> : null}
                                    {process.env.REACT_APP_VERSION != 'sb:user:en' ? <input
                                        type="text"
                                        name="nif"
                                        value={this.state.nif}
                                        placeholder={t('placeholder.vat_number', "NIF")}
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        className="m-top-4"
                                    /> : null}
                                    {errors.nif ? <div className="error">{errors.nif}</div> : null}
                                    {process.env.REACT_APP_VERSION != 'sb:user:en' ? <input
                                        type="text"
                                        name="code"
                                        value={this.state.code}
                                        placeholder={t('placeholder.client_code', "Código de Cliente")}
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        className="m-top-4"
                                    /> : null}
                                    {errors.code ? <div className="error">{errors.code}</div> : null}

                                    {process.env.REACT_APP_VERSION === 'sb:user:en' ?
                                        <select name="country" id="country" onChange={this.handleChange} className="m-top-4">
                                            <option key="" value="">Select country</option>
                                            {countries.map(item => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))}
                                        </select> : null}
                                    {errors.country ? <div className="error">{errors.country}</div> : null}
                                    <input
                                        type="email"
                                        name="email"
                                        value={this.state.email}
                                        placeholder={t('placeholder.email', "E-mail")}
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        className="m-top-20"
                                    />
                                    {errors.email ? <div className="error">{errors.email}</div> : null}
                                    <input
                                        type="password"
                                        name="password"
                                        value={this.state.password}
                                        placeholder={t('placeholder.password', "Password")}
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        className="m-top-4"
                                    />
                                    {errors.password ? <div className="error">{errors.password}</div> : null}
                                    <input
                                        type="password"
                                        name="password2"
                                        value={this.state.password2}
                                        placeholder={t('placeholder.confirm_password', "Confirmar password")}
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        className="m-top-4"
                                    />
                                    {errors.password2 ? <div className="error">{errors.password2}</div> : null}
                                    <div className="row m-top-4">
                                        <div className="label">
                                            <h2>{t('admin_signup.txt_terms_and_conditions', "Declaro que concordo com os ")}<a href={t('admin_signup.url_terms_and_conditions', "/pdf/termos-e-condicoes.pdf")} target="_blank">{t('admin_signup.label_terms_and_condtions', "Termos e Condições")}</a>.</h2>
                                        </div>
                                        <RadioGroup id="terms" name="number" options={[t('common.txt_yes', "Sim")]} selected={this.state.terms ? t('common.txt_yes', "Sim") : ''} onChange={this.handleToggle} />
                                    </div>
                                    <div className="row m-top-4">
                                        <div className="label">
                                            <h2>{t('admin_signup.txt_privacy_policy', "Declaro que concordo com a ")}<a href={t('admin_signup.url_privacy_policy', "/pdf/politica-de-privacidade.pdf")} target="_blank">{t('admin_signup.label_privacy_policy', "Política de privacidade")}</a>.</h2>
                                        </div>
                                        <RadioGroup id="privacy" name="number" options={[t('common.txt_yes', "Sim")]} selected={this.state.privacy ? t('common.txt_yes', "Sim") : ''} onChange={this.handleToggle} />
                                    </div>
                                </form>
                                <div className="m-top-4">
                                    <a onClick={this.handleSubmit} className="btn-primary">
                                        {t('btn.signup', 'Registar')}
                                    </a>
                                </div>
                                <div className="m-top-8 txt-primary txt-center">
                                    <h5>{t('admin_signup.txt_login', "Já tem conta? Faça login.")}</h5>
                                </div>
                                <div className="m-top-8 m-bottom-10">
                                    <a href="/login" className="btn-secundary">
                                        {t('btn.login', 'Login')}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <ErrorPopup message={genericError} onClose={this.closePopup} />
                    </div>
                    </div>
                );
            } else {
                return (
                    <div className="loader">
                        <img src="/images/loader.gif" alt="" />
                    </div>
                );
            }
        }
        else {
            return (
                <div className="menu">
                    <div className="app forms">
                    <header className="admin-header">
                        <figure className="logo">
                            <img className="admin-logo" src={t('common.logo_url', '/images/logo.svg')} alt="" />
                        </figure>
                    </header>
                    <div className="content">
                        <div className="form">
                            <div className="txt-primary txt-center">
                                <h3>{t('admin_signup.txt_success_title', "Obrigado por se registar.")}<br></br>{t('admin_signup.txt_success_details', "Vamos enviar-lhe um e-mail de confirmação para validar a sua conta.")}</h3>
                            </div>
                            <div className="m-top-8 m-bottom-10">
                                <a href="/login" className="btn-secundary">
                                    {t('btn.login', 'Login')}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            );
        }
    }
}

Signup.contextType = ServicesContext;

export default withTranslation()(Signup);