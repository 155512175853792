import React, { Component } from 'react';

import { ServicesContext } from '../../../context/services';
import { withTranslation } from 'react-i18next';
import "./PickItemContent.scss";

import Carousel from 'react-elastic-carousel';

class PickItemContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            list: props.data.list,
            show_english: props.data.show_english,
            data: null
        };

        this.onComplete = props.onComplete;
    }

    componentDidMount() {
        this.getProducts();
    }
    /*
    componentDidUpdate(){
        if(this.state.style){
            const dots = document.body.getElementsByClassName('rec-dot');
            let i;
            if(dots.length > 0){
                for (i = 0; i < dots.length; i++) {
                    dots.item(i).style.backgroundColor = this.state.style.buttons.color['color'];
                }
            }
            const arrows = document.body.getElementsByClassName('rec-arrow');
            let v;
            if(arrows.length > 0){
                for (v = 0; v < arrows.length; v++) {
                    arrows.item(v).style.color = this.state.style.buttons.color['color'];
                }
            }
        }
    }
    */
    
    getProducts = () => {
        const { services } = this.context;

        services.productsApi.getAll()
            .then(({ allProducts }) => {
                this.setState({ loading: false, data: { allProducts } })
            });
    }

    onSelected = () => {
        const product = this.state.data.allProducts[this.carousel.state.activePage];
        
        this.setState({ loading: true });

        const { services } = this.context;
        const name = `{ create: { pt: "${product.name.pt}", en: "${product.name.en ? product.name.en : ''}" } }`;
        const description = `{ create: { pt: "${product.description && product.description.pt ? product.description.pt : '' }", en: "${product.description && product.description.en ? product.description.en : ''}" } }`;
        const image = product.image ? product.image.publicUrl : null;
        const data = `{ name: ${name}, description: ${description}, price: 0, lists: { connect: { id: "${this.state.list}" } }, image: "${image}" }`;

        services.itemsApi.add(data)
            .then(result => {
                if (result && result.createItem) {
                    this.onComplete({popup:'pick', item:result.createItem});
                } else console.log(result.errors);
            });
            
    }
    
    render() {
        const { loading, data } = this.state;
        const { t } = this.props;
        let productsHtml = null;
        if (data && data.allProducts) {
            productsHtml = data.allProducts.map((product, index) => {
                return (
                    <div className="item-product" id={product.id} key={product.id}>
                        <figure className="m-top-4">
                            {product.image ? <img src={product.image.publicUrl} alt={product.name.pt} /> : <img src="/images/thumb-item.jpg" />} 
                        </figure>
                        <div className="m-top-8">
                            <span>{product.name.pt}</span>
                        </div>
                    </div>
                )
            })
        }
        return (
            <div className="pick-item">
                <h2>{t('admin_pick_product.title', 'Produto Super Bock Group')}</h2>
            {!loading ? 
                <div className="m-top-4">
                    <div className="">
                        <Carousel ref={ref => (this.carousel = ref)} showArrows={true} pagination={true} enableSwipe={true} enableMouseSwipe={true} >
                            {productsHtml}
                        </Carousel>
                        <div className="m-top-4">
                            <div className="btn-primary" onClick={() => this.onSelected()}>{t('btn.select', 'Seleccionar')}</div>
                        </div>
                    </div>
                </div>
            :  
                <div className="loader">
                    <img src="/images/loader.gif" alt="" />
                </div>
            }
            </div>
        )
    }
}

PickItemContent.contextType = ServicesContext;

export default withTranslation()(PickItemContent);