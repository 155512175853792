export default function makeAuthApi({ query, mutation, request }) {  
    const setUser = (data) => {
      localStorage.setItem(process.env.REACT_APP_VERSION, JSON.stringify(data));
    }

	const userFragment = `
		id,
		name,
		country {
			lang_pt
			currency
		}
	`;
	return {
		sendForgotPasswordEmail: options => request('password', {
			method: 'POST',
			data: options,
		}),
		changeUserPasswordById: (id, password) => mutation(`
            mutation {
				updateUser(id: "${id}", data: {
					password: "${password}"
				}) { id }
			}
        `),
		login: (email, password) => mutation(`
			mutation {
				authenticateUserWithPassword(email: "${email}", password: "${password}") {
					item {
						${userFragment}
					}
				}
			}
		`).then(({ authenticateUserWithPassword: { item } }) => {
            setUser(item);
            return item
        }),
		logout: () => mutation(`
			mutation {
				unauthenticateUser {
					success
				}
			}
		`),
		getUser: () => new Promise((resolve, reject) => {
			query(`
				query {
					authenticatedUser {
						${userFragment}
					}
				}
			`).then(({ authenticatedUser }) => {
				if (authenticatedUser) {
                    setUser(authenticatedUser)
                    resolve(authenticatedUser);
                } else {
                    reject();
                }
			});
		}),
	};
}