export default function makeContriesApi({
	query,
	mutation,
}) {
	const countryFragment = `{
        id,
        name
    }`;

	return {
        getAll: () => query(`
            query {
                allCountries ${countryFragment}
            }
        `),
	};
}