import React from 'react';
import './LandingPage.scss';
import { useTranslation } from 'react-i18next';

const LandingPage = () => {
    const { t } = useTranslation();

    return (
        <div className="landing">
            <header className="header">
                <div className="container">
                    <div className="logo-container">
                        <img className="logo" src={t('common.logo_url', '/images/logo.svg')} alt={t('common.site_name', 'Menu Autêntico')} />
                    </div>
                    <div className="button">
                        <a href="/admin">{t('btn.login', 'Login')}</a>
                    </div>
                </div>
            </header>
            <section className="hero">
                <div className="circle">
                    <div className="hero-image"></div>
                </div>
                <div className="container" style={{backgroundImage: `url(${t('landing.img_smartphone-small', "images/landing/smartphone-small.png")})`}}>
                    <div className="heading">
                        <h1 dangerouslySetInnerHTML={{__html:t('landing.title', '<span>Uma nova forma,</span> <span>100% digital,</span> <span>de aceder ao menu</span> <span>do seu restaurante.</span>')}} />
                        <div className="button">
                            <a href="/signup" className="brown">
                                {t('btn.signup_here', 'Registe-se aqui')}
                            </a>
                        </div>
                    </div>

                    <div className="smartphones">
                        <img src={t('landing.img_smartphone', "images/landing/smartphone.png")} alt="Smartphone" />
                    </div>  
                </div>
            </section>
            <section className="details">
                <div className="container">
                    <div className="qr-code">
                        <img src={t('landing.img_qrcode', "images/landing/qr-code-full.png")} alt="QR Code" />
                        <p className="qr-code__description" dangerouslySetInnerHTML={{__html: t('landing.txt_qrcode', 'Através <br />de um QR Code, <br />o Menu Autêntico<br /> fica disponível<br /> no telemóvel <br />dos seus clientes.')}} />
                    </div>
                    <div className="advantages">
                        <h5 className="advantages__title">{t('landing.txt_benefits', 'Vantagens')}</h5>
                        <div className="advantages__items">
                            <ul>
                                <li>
                                    <img src="images/landing/icons/lp1-01.svg" alt="" />
                                    <span>{t('landing.txt_benefit1', 'Mais segurança para si e para o seu cliente.')} </span>
                                </li>
                                <li>
                                    <img src="images/landing/icons/lp2-01.svg" alt="" />
                                    <span>{t('landing.txt_benefit2', 'Interface fácil e intuitivo.')}</span>
                                </li>
                                <li>
                                    <img src="images/landing/icons/lp3-01.svg" alt="" />
                                    <span>{t('landing.txt_benefit3', 'Opções de personalização.')}</span>
                                </li>
                                <li>
                                    <img src="images/landing/icons/lp4-01.svg" alt="" />
                                    <span
                                    >{t('landing.txt_benefit4', 'Oferta de materiais de comunicação com QR Code do seu Menu Autêntico.')}</span>
                                </li>
                                {process.env.REACT_APP_VERSION != 'sb:user:en' ?
                                    <li>
                                        <img src="images/landing/icons/lp5-01.svg" alt="" />
                                        <span
                                        >{t('landing.txt_benefit5', 'Possibilidade de publicar a sua ementa em Português e Inglês.')}</span
                                        >
                                    </li>
                                : null }
                                <li>
                                    <img src="images/landing/icons/lp6-01.svg" alt="" />
                                    <span>{t('landing.txt_benefit6', 'Tudo isto e muito mais sem custos associados.')}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="footer">
                <div className="links">
                    <div className="container">
                        <div className="links__logo">
                            <img src="images/landing/logo-SB.png" alt="Super Bock" />
                        </div>
                        <div className="links__items">
                            <a href="https://autentico.superbockgroup.com/" target="_blank">Super Bock Group</a>
                            <a href="/pdf/politica-de-privacidade.pdf" target="_blank">{t('landing.txt_privacy_policy','Política de Privacidade')}</a>
                            <a href="https://autentico.superbockgroup.com/info-legal" target="_blank">{t('landing.txt_legal_info','Informação legal')}</a>
                        </div>
                    </div>
                </div>
                <div className="copyrights">
                    <div className="container">
                        <p>{t('landing.txt_copyright','© 2020 Todos os direitos reservados')}</p>
                        <p>{t('landing.txt_phonenumber','229 052 890')}</p>
                        <p><a href="mailto:autentico@superbockgroup.com">{t('landing.txt_email','229 052 890')}</a></p>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default LandingPage;